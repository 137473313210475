import React, { Component, Fragment } from 'react';
import Logo from './assets/prospernav2-log.png';
import './WebsiteUnavailable.css';

export class WebsiteUnavailable extends Component {
	render() {
		return (
			<Fragment>
				<div className="x-container">
					<div className="x-heading">
						<h1>Website temporarily unavailable.</h1>
					</div>
					<div className="x-content">
						<p>Powered by:</p>
						<img className="logo" alt="Prosperna" src={Logo} />
					</div>
				</div>

				<div className="xfooter-container">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</div>
			</Fragment>
		);
	}
}

export default WebsiteUnavailable;
