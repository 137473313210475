import * as types from './types';

const initialState = {
    message: '',
    data: [
        {
            product_id: -1,
            product_name: '',
            description: '',
            amount: 0,
            currency: '',
            product_thumbnail_link: null
        }
    ],
    pagination_data: {}
};

function productsReducer(state = initialState, action) {
    let new_state = state;
    switch (action.type) {
        case types.FETCH_PRODUCT_LISTING_SUCCESS:
            new_state = Object.assign({}, state, { ...state, pagination_data:action.payload.data , data: action.payload.data.data });
            break;
        case types.FETCH_PRODUCT_LISTING_FAIL:
            new_state = Object.assign({}, state, { ...state, ...action.payload, data: [] });
            break;
        default:
            break;
    }
    return new_state;
}
export default productsReducer;
