import React, { Component } from 'react';
import './App.css';
import Logo from './components/assets/prospernav2-log.png';
import { Route, BrowserRouter, Redirect, Switch, withRouter } from 'react-router-dom';
import FormStepper from './components/FormStepper';
import Login from './components/Login';
import ShoppingCart from './components/ShoppingCart';
import MyProducts from './components/MyProducts';
import { verifySesion } from './services/session.service';
import { connect } from 'react-redux';
import PaidInvoice from './components/PaidInvoice';
import UnpaidInvoice from './components/UnpaidInvoice';
import { authSession } from './store/user/user.action';
import ThankYouPage from './components/ThankYouPage';
import PaypalCheckout from './components/PaypalCheckout';
import { Suspension } from './components/Suspension';
import { WebsiteUnavailable } from './components/WebsiteUnavailable';
import AdminLogin from './components/AdminControlPortal/AdminLogin';
import AdminDashboard from './components/AdminControlPortal/AdminDashboard';

// reference Signup variable to form stepper to increase readability
const Signup = FormStepper;
const apiBaseUrl = process.env.REACT_APP_API_URL;
// a modifeid private route component
// from https://github.com/dabit3/react-authentication-in-depth/blob/master/src/Router.js

class PrivateRoute extends React.Component {
	state = {
		loaded: false,
		isAuthenticated: false
	};

	componentDidMount() {
		this.authenticate();
		this.unlisten = this.props.history.listen(() => {
			this.props
				.dispatch(authSession())
				.then((user) => {
					console.log('user: ', user);
				})
				.catch((err) => {
					console.log('user: err', err);
					if (this.state.isAuthenticated) this.setState({ isAuthenticated: false });
				});
		});
	}

	componentWillUnmount() {
		this.unlisten();
	}

	authenticate() {
		this.props
			.dispatch(authSession())
			.then(() => {
				this.setState({ loaded: true, isAuthenticated: true });
			})
			.catch(() => this.props.history.push('/login'));
	}

	render() {
		const { component: Component, ...rest } = this.props;
		const { loaded, isAuthenticated } = this.state;
		if (!loaded) return <p>Loading...</p>;
		return (
			<Route
				{...rest}
				render={(props) => {
					return isAuthenticated ? (
						<Component {...props} />
					) : (
						<Redirect
							to={{
								pathname: '/auth'
							}}
						/>
					);
				}}
			/>
		);
	}
}
function mapStateToProps(state) {
	return {
		auth: { ...state.authReducer }
	};
}

PrivateRoute = connect(mapStateToProps)(withRouter(PrivateRoute));

class App extends Component {
	constructor() {
		super();
		this.state = {
			inAppBannerMsg: ''
		};
	}
	
	componentDidMount() {
		// window.location.replace("https://www.prosperna.com/maintenance");
		const demoCheckout = this.getUrlVars()["demo-checkout"];
		const pid = this.getUrlVars()["pid"];
		const indId = this.getUrlVars()["ind-id"];
		const setup_type = this.getUrlVars()["sp"];
		const seo_type = this.getUrlVars()["sl"];

		const redirect = this.getUrlVars()["redirect"];

		if(demoCheckout != undefined || pid != undefined || indId != undefined || setup_type != undefined || seo_type != undefined){
			let userDemoCheckout = {};
			userDemoCheckout.demoCheckout = demoCheckout;
			userDemoCheckout.pid = pid;
			if(indId === "1"){
				userDemoCheckout.ind = "Electronics";
			}
			else if(indId === "2"){
				userDemoCheckout.ind = "Real Estate";
			}
			else if(indId === "3"){
				userDemoCheckout.ind = "Engineering";
			}
			if(setup_type === "bs"){
				userDemoCheckout.setup_type = "basic";
				userDemoCheckout.setup_price = 149.00;
			}
			else if(setup_type === "ps"){
				userDemoCheckout.setup_type = "premium";
				userDemoCheckout.setup_price = 299.00;
			}
			else{
				userDemoCheckout.setup_type = "none";
				userDemoCheckout.setup_type = 0;
			}
			if(seo_type === "bos"){
				userDemoCheckout.seo_type = "basic";
				userDemoCheckout.seo_price = 149.00;
			}
			else{
				userDemoCheckout.seo_type = "none";
				userDemoCheckout.seo_price = 0;
			}

			localStorage.setItem("userDemoCheckout", JSON.stringify(userDemoCheckout));
		}
		if(redirect != undefined){
			localStorage.setItem("redirect", redirect);
		}
	}

	getUrlVars() {
		let vars = {};
		let parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
			vars[key] = value;
		});
		return vars;
	}

	render() {
		return (
			<div className="App">
				<BrowserRouter>
					<Switch>
						<PrivateRoute path="/shopping-cart" component={ShoppingCart} />
						<PrivateRoute path="/admin-dashboard" component={AdminDashboard} />
						<PrivateRoute path="/my-products" component={MyProducts} />
						<PrivateRoute path="/paid-invoice/" component={PaidInvoice} />
						<PrivateRoute path="/thankyou/:invoice_id" component={ThankYouPage} />
						<PrivateRoute path="/unpaid-invoice" component={UnpaidInvoice} />
						<PrivateRoute path="/paypal-checkout" component={PaypalCheckout} />
						<Route path="/suspended" component={Suspension} />
						<Route path="/login" component={Login} />
						<Route path="/mp-admin" component={AdminLogin} />
						<Route exact path="/" component={Signup} />
						<Route exact path="*">
							<Redirect to="/" />
						</Route>
					</Switch>
				</BrowserRouter>
			</div>
		);
	}
}

export default connect(mapStateToProps)(App);
