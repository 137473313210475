import React, { Component } from 'react';
import Logo from './assets/prospernav2-log.png';
import ShoppingCartLogo from './assets/shopping-cart-icon.png';
import MyProductsLogo from './assets/my-products-icon.png';
import './Styles.css';
import LogoutLogo from './assets/exit.png';
import Button from '@material-ui/core/Button';
import avatar from './assets/real-estate-agency-tb.png';
import { connect } from 'react-redux';
import { fetchProductsListings, fetchProductsListingsPage } from '../store/products/products.action';
import { Route, BrowserRouter, Redirect, Switch, withRouter } from 'react-router-dom';
import { postUserCheckout } from '../store/invoice/invoice.action';
import { TablePagination } from '@material-ui/core';
import InAppBanner from './InAppBanner';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ShoppingCartTab from './ShoppingCartTab';
import MyProductsTab from './MyProductsTab';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

export class MyProducts extends Component {
	state = {
		openSideNav: false,
	}
	componentDidMount() {

	}
	render() {
		const classes = makeStyles((theme) => ({
			root: {
			  flexGrow: 1,
			},
			menuButton: {
			  marginRight: theme.spacing(2),
			},
			title: {
			  flexGrow: 1,
			},
		}));
		return (
			<div className="main-container">
				{
					window.screen.width < 992 ?
					<React.Fragment>
						<AppBar position="fixed">
							<Toolbar>
									<img alt="Prosperna" className="logo" src={Logo} width="140"/>
									<IconButton 
										edge="start" 
										className={classes.menuButton} 
										color="inherit" aria-label="menu" 
										onClick={() => {
											this.setState({
												...this.state,
												openSideNav:true
											})
										}}
									>
										<MenuIcon />
									</IconButton>
							</Toolbar>
						</AppBar>
						<SwipeableDrawer
							anchor={'right'}
							open={this.state.openSideNav}
							onClose={() => {
								this.setState({
									...this.state,
									openSideNav:false
								})
							}}
							onOpen={() => {
								this.setState({
									...this.state,
									openSideNav:true
								})
							}}
						>
							<List className="avatar-wrap">
								<Avatar>{this.props.user.first_name.charAt(0)+this.props.user.last_name.charAt(0)}</Avatar>
								<h5 className="text-center m-0 mt-1">{this.props.user.first_name+" "+this.props.user.last_name}</h5>
								<p className="text-center m-0">{this.props.user.email_address}</p>
								<p className="text-center m-0">{this.props.user.phone}</p>
							</List>
						
						
							<List>
								<ListItem button onClick={() => {window.location.href='/shopping-cart'}}>
									<ListItemIcon><img alt="shopping-cart-logo" src={ShoppingCartLogo} width="25px" /></ListItemIcon>
									<ListItemText className="font-bold" primary={'Shopping Cart'} />
								</ListItem>
								<ListItem button onClick={() => {window.location.href='/my-products'}}>
									<ListItemIcon><img alt="shopping-cart-logo" src={MyProductsLogo} width="25px" /></ListItemIcon>
									<ListItemText primary={'My Products'} />
								</ListItem>
							</List>
							<Divider />
							<List>
								<ListItem button onClick={() => {window.location.href='/login'}}>
									<ListItemIcon><img alt="shopping-cart-logo" src={LogoutLogo} width="25px" /></ListItemIcon>
									<ListItemText className="font-bold" primary={'Logout'} />
								</ListItem>
							</List>
						</SwipeableDrawer>
					</React.Fragment>
					:
					<React.Fragment>
						<div className="main-header">
							<div className="container">
								<div className="row">
									<div className="col-lg-6">
										<div className="header-logo">
											<img alt="Prosperna" className="logo" src={Logo} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="header-nav">
											<Button href="/shopping-cart">
												<span><img alt="shopping-cart-logo" src={ShoppingCartLogo} /></span>
												Shopping Cart
											</Button>
											<Button href="/my-products">
												<span><img alt="shopping-cart-logo" src={MyProductsLogo} /></span>
												My Products
											</Button>
											<Button className="logout-btn" href="/login">
												Logout
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div> 
					</React.Fragment>
				}
				{/* <InAppBanner /> */}
				<div id="test-modal" className="my-products container">
					<MyProductsTab />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	// console.log('fetchProductsListings response', state);
	return {
		products: { ...state.productsReducer },
		invoice: { ...state.invoiceReducer },
		user: { ...state.userReducer },
	};
}

export default connect(mapStateToProps)(MyProducts);

