import React, { Component } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/dist/style.css';
import ErrorIcon from '@material-ui/icons/Error';

export class FormPersonalDetails extends Component {
	constructor() {
		super();
		this.state = {
			phoneNumber: '',
			country: ''
		};
	}
	next = (e) => {
		e.preventDefault();
		// avoid next step if the required are not fulfilled
		const required = {
			phoneNumber: this.state.phoneNumber,
			country: this.state.country
		};
		console.log('req required', required);
		for (const key in required) {
			if (required.hasOwnProperty(key)) {
				const value = required[key];
				if (!value || value === '') {
					return;
				}
			}
		}
		this.props.nextStep();
	};

	back = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};

	updateContry = (value, data) => {
		const { handleChange } = this.props;
		this.setState({ phoneNumber: value, country: data.name }, () => {
			if (data && data.name) {
				handleChange('phoneNumber')({ target: { value: value.replace(/\s/g, '') } });
				handleChange('country')({ target: { value: data.name } });
			}
		});
	};

	render() {
		const { values, handleChange } = this.props;
		return (
			<div className="form-container">
				<header className="form-header">
					<img className="logo" alt="Prosperna" src={Logo} />
				</header>

				<div className="form-description">
					<h1>
						Awesome <span className="bold-name">{values.firstName}</span>! What's your cell phone number?
					</h1>
					{
						window.screen.width <= 991 ?
						<p>Let us know in case we need to help <br/>you along the way.</p>
						:
						<p>Let us know in case we need to help you along the way.</p>
					}
				</div>
				<div className="error-container" style={{ visibility: 'hidden' }}>
					<div className="error-icon">
						<ErrorIcon color="error" />
					</div>
					<div className="error-txt">
						<p>Please fill in Username and Password</p>
					</div>
				</div>

				<form className="form-fields contact-info">
					<div className="form-row">
						<PhoneInput
							value={this.state.phoneNumber}
							defaultCountry="ph"
							enableSearchField={true}
							inputExtraProps={{
								name: 'phone',
								required: true,
								autoFocus: true
							}}
							onChange={this.updateContry}
						/>
					</div>

					<div className="helper-txt">
						<p>*Required</p>
					</div>
				</form>
				<div className="stepper-btn-container">
					<Button className="back-btn" onClick={this.back}>
						Back
					</Button>

					<Button className="next-btn" onClick={this.next}>
						Next
					</Button>

					<p style={{ visibility: 'hidden' }}>
						Already have an account?{' '}
						<Link className="login-link" href="./Login">
							{' '}
							Log In
						</Link>
					</p>
				</div>

				<div className="footer-container">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</div>
			</div>
		);
	}
}

export default FormPersonalDetails;
