import React, { Component } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import EmailIcon from '@material-ui/icons/Email';
import Link from '@material-ui/core/Link';

export class PaypalCheckout extends Component {
	render() {
		return (
			<div className="paypal-checkout-container">
				<header className="form-header">
					<img className="logo" alt="Prosperna" src={Logo} />
				</header>

				<div className="paypal-container">
					<p className="">Complete your payment using PayPal</p>
					<div className="paypal-details-container">
						<div className="info">
							<p className="highlight-txt">Ian Vincent Pelea</p>
							<p>
								Unit 503 Prime Land Building, Madrigal<br />
								Business Park, Alabang, Muntinlupa City,<br />
								NCR, Philippines
							</p>
							<p>
								<EmailIcon /> ian@xtendly.com
							</p>
						</div>
						<div className="price">
							<p>
								<span className="pricex">$18.00</span>
								<br />
								TOTAL
							</p>
						</div>
					</div>
					<div className="paypal-btn-container">
						<Link className="paypal-link" target="_blank" href="/#">
							{' '}
							PayPal Checkout
						</Link>
					</div>
				</div>

				<div className="paypal-footer-container">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</div>
			</div>
		);
	}
}

export default PaypalCheckout;
