import React, { Component } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ErrorIcon from '@material-ui/icons/Error';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';

export class FormJobPositionDetails extends Component {
	constructor() {
		super();
		this.state = {
			position: ''
		};
	}
	next = (e) => {
		e.preventDefault();
		const required = {
			position: this.state.position
		};
		for (const key in required) {
			if (required.hasOwnProperty(key)) {
				const value = required[key];
				if (!value || value === '') {
					return;
				}
			}
		}
		this.props.nextStep();
	};

	back = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};

	change = (name) => (event) => {
		// console.log('event.target.value event.target.value ',event.target.value)
		localStorage.setItem('industry', event.target.value);
		this.setState({ [name]: event.target.value });
		this.props.handleChange(name)(event);
	};

	render() {
		// const { values, handleChange } = this.props;
		return (
			<div className="form-container">
				<header className="form-header">
					<img className="logo" alt="Prosperna" src={Logo} />
				</header>

				<div className="form-description">
					<h1>Alrighty, now tell us about your business.</h1>
					<p>This way we can personalize your experience.</p>
				</div>
				<div className="error-container" style={{ visibility: 'hidden' }}>
					<div className="error-icon">
						<ErrorIcon color="error" />
					</div>
					<div className="error-txt">
						<p>Please fill in this details</p>
					</div>
				</div>

				<form className="form-select-fields">
					<div className="form-row select-jobPosition">
						<JobPositionSelects handleChange={this.change} />
					</div>

					<div className="helper-txt" style={{ visibility: 'hidden' }}>
						<p>*Required</p>
					</div>
				</form>
				<div className="stepper-btn-container">
					<Button className="back-btn" onClick={this.back}>
						Back
					</Button>

					<Button className="next-btn" onClick={this.next}>
						Next
					</Button>

					<p style={{ visibility: 'hidden' }}>
						Already have an account?{' '}
						<Link className="login-link" href="./Login">
							{' '}
							Log In
						</Link>
					</p>
				</div>

				<div className="footer-container">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</div>
			</div>
		);
	}
}

export default FormJobPositionDetails;

const useStyles = makeStyles((theme) => ({
	formControl: {
		width: '550px'
	}
}));

export function JobPositionSelects({ handleChange }) {
	const classes = useStyles();
	const [ state, setState ] = React.useState({
		position: '',
		name: 'hai'
	});

	const inputLabel = React.useRef(null);
	const [ labelWidth, setLabelWidth ] = React.useState(0);
	React.useEffect(() => {
		setLabelWidth(inputLabel.current.offsetWidth);
	}, []);

	const handleChangeInternal = (name) => (event) => {
		setState({
			...state,
			[name]: event.target.value
		});
		handleChange(name)(event);
	};

	return (
		<div>
			<FormControl variant="outlined" className={classes.formControl}>
				<InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
					Industry
				</InputLabel>
				<Select
					native
					value={state.position}
					onChange={handleChangeInternal('position')}
					labelWidth={labelWidth}
					inputProps={{
						name: 'position',
						id: 'industry-picklist'
					}}
				>
					<option value="" />
					<option value={"Art & Photography"}>Art & Photography</option>
					<option value={"Clothing & Fashion"}>Clothing & Fashion</option>
					<option value={"Electronics"}>Electronics</option>
					<option value={"Food & Beverage"}>Food & Beverage</option>
					<option value={"Furniture"}>Furniture</option>
					<option value={"Health & Beauty"}>Health & Beauty</option>
					<option value={"Home & Garden"}>Home & Garden</option>
					<option value={"Jewelry & Accessories"}>Jewelry & Accessories</option>
					<option value={"Professional Services"}>Professional Services</option>
					<option value={"Real Estate"}>Real Estate</option>
					<option value={"Sports & Recreation"}>Sports & Recreation</option>
					<option value={"Technology"}>Technology</option>
					<option value={"Toys & Games"}>Toys & Games</option>
					<option value={"Other"}>Other</option>
					{/*<option value={30}>Real Estate Developer</option>*/}
				</Select>
			</FormControl>
		</div>
	);
}
