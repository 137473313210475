import React, { Component } from 'react';
import { Button, Form, Col } from 'react-bootstrap';
import Logo from '../assets/prospernav2-log.png';
import TextField from '@material-ui/core/TextField';
import { verifyCredentials } from '../../store/user/user.action';
import { Redirect } from 'react-router-dom';
import { resetSession } from '../../services/session.service';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { forgotPassword } from '../../services/userDetails.service';
import Slide from '@material-ui/core/Slide';

class AdminLogin extends Component {
    state = { 
        email: "",
        password: "",
        role: '2',
        validated: false
    }
    componentWillMount() {
		resetSession();
	}
    handleChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.dispatch(verifyCredentials(this.state.email, this.state.password, this.state.role)).then(
			() => {
				this.setState({ errorMessage: undefined });
				this.props.history.push('/admin-dashboard');
			},
			(error) => {
				this.setState({ errorMessage: error });
				console.log('setstate error', error);
			}
		);
    };
    render() { 
        if (this.state.authenticated) {
			return <Redirect to="/admin-dashboard" />;
		}
        return ( 
            <React.Fragment>
                <header className="form-header">
					<img className="logo" alt="Prosperna" src={Logo} />
				</header>
                <section id="admin-login">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center mb-5">
                                <div className="form-description">
                                    <h1>Admin Control Portal</h1>
                                </div>
                            </div>
                            <div className="col-lg-8 mx-auto">
                                <Form noValidate validated={this.state.validated} onSubmit={this.handleSubmit.bind(this)}>
                                    <Form.Row className="text-left">
                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            <TextField
                                                name="email"
                                                onChange={this.handleChange.bind(this)}
                                                value={this.state.email}
                                                label={"Email Address"}
                                                variant="outlined"
                                                className="input-txt form-control"
                                            />
                                           
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your Email!
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <br /><br /><br />
                                        <Form.Group as={Col} md="12" controlId="validationCustom02">
                                            <TextField
                                                name="password" 
                                                onChange={this.handleChange.bind(this)}
                                                value={this.state.password}
                                                label="Password"
                                                variant="outlined"
                                                type="password"
                                                className="input-txt form-control"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please enter your Password!
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Button type="submit" className="form-button">LOGIN</Button>
                                </Form>
                                <ForgotPassword />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="footer-container">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
	console.log('state', state);
	return {
		credentials: { ...state }
	};
}
export default connect(mapStateToProps)(AdminLogin);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export function ForgotPassword() {
	const [ open, setOpen ] = React.useState(false);
	const [ email, setEmail ] = React.useState('');

	const handleClickOpen = (e) => {
		e.preventDefault();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleForgotPassword = () => {
		forgotPassword(email).then((response) => {
			// console.log('response', response.data);
			alert(response.data.message);
		});
		handleClose();
	};

	return (
		<div>
			<p>Forgot your <a href="/" onClick={handleClickOpen.bind(this)}>password?</a></p>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{'Forgot Password'}</DialogTitle>
				<DialogContent>
					<form className="form-fields-modal">
						{/* <DialogContentText id='alert-dialog-slide-description' /> */}
						<TextField
							value={email || ''}
							onChange={(event) => {
								setEmail(event.target.value);
							}}
							className="hover-field"
							label="Email Address"
							fullWidth
							variant="outlined"
						/>
					</form>
				</DialogContent>
				<DialogActions>
					<Button className="forgot-cancel-btn" onClick={handleClose}>
						Cancel
					</Button>
					<Button className="forgot-submit-btn" onClick={handleForgotPassword}>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}