import React, { Component, Fragment } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

export class UnpaidInvoice extends Component {
	render() {
		return (
			<Fragment>
				<div className="main-header">
					<div className="header-logo">
						<img alt="Prosperna" className="logo" src={Logo} />
					</div>
				</div>

				<div className="invoice-container">
					<div className="invoice-desc-pdf">
						<div className="invoice-desc">
							<p>Invoice #00000 - January 00, 0000 </p>
						</div>
						<div className="invoice-pdf">
							<Button className="pdf-btn" href="/">
								<PictureAsPdfIcon /> PDF
							</Button>
						</div>
					</div>
					<div className="invoice-payment-desc">
						<div className="inv-payment-from">
							<p>
								<span className="payment-txt">Payment From:</span>
								<br />
								<span className="payment-name">Lorem Ipsum</span>
								<br />Lorem ipsum dolor sit amet<br />Lorem ipsum dolor sit amet<br />Lorem ipsum dolor
								sit amet
							</p>
						</div>
						<div className="inv-payment-to">
							<p>
								<span className="payment-txt">Payment To:</span>
								<br />
								<span className="payment-name">Lorem Ipsum</span>
								<br />Lorem ipsum dolor sit amet<br />Lorem ipsum dolor sit amet<br />Lorem ipsum dolor
								sit amet
							</p>
						</div>
					</div>
					<div className="invoice-details-container">
						<div className="invoice-details-content">
							<div className="invoice-row-1">
								<div className="invoice-status">
									<div className="invoice-txt">Invoice</div>
									<div className="fade-txt subtxt">Invoice #</div>
									<div className="fade-txt subtxt">Billed On</div>
									<div className="fade-txt subtxt">Transaction Type</div>
									<div className="fade-txt subtxt">Plan Type</div>
									<div className="fade-txt subtxt">Status</div>
								</div>
								<div className="invoice-desc">
									<div className="invoice-txt" style={{ visibility: 'hidden' }}>
										Invoice
									</div>
									<div className="subtxt">00000</div>
									<div className="subtxt">January 00, 0000</div>
									<div className="subtxt">Lorem ipsum</div>
									<div className="subtxt">Lorem</div>
									<div className="subtxt">
										<Button className="unpaid-btn">Unpaid</Button>
									</div>
								</div>
							</div>
							<div className="invoice-row-2">
								<div className="invoice-due">
									<table className="dueDate-tbl">
										<tbody>
											<tr>
												<th class="highlight">DUE DATE</th>
												<th>January 00, 0000</th>
											</tr>
											<tr>
												<td
													colspan="2"
													align="center"
													class="amount custom-amount"
													data-amount="1"
												>
													Php 00,000
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<table id="invoice-desc">
							<tbody>
								<tr>
									<th width="30%">Date</th>
									<th>Details</th>
									<th width="15%">Amount</th>
									<th width="15%">Subtotal</th>
								</tr>
								<tr>
									<td>January 00, 0000 - January 00, 0000</td>
									<td>Lorem ipsum dolor sit amet</td>
									<td class="amount" data-amount="1">
										Php 0
									</td>
									<td class="amount" data-amount="1">
										Php 0
									</td>
								</tr>
								<tr>
									<td />
									<td />
									<td>Subtotal</td>
									<td class="sub-total-amount amount" data-amount="1">
										Php 0
									</td>
								</tr>
								<tr class="greyfill">
									<td />
									<td />
									<td class="total">Credits</td>
									<td class="credits total" data-amount="0">
										Php 0
									</td>
								</tr>
								<tr class="greyfill">
									<td />
									<td />
									<td class="due">TOTAL</td>
									<td class="grand-total-amount due">Php 1</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default UnpaidInvoice;
