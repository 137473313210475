import axios from 'axios';
import { getAuthorizationToken } from '../services/session.service';
const apiBaseUrl = process.env.REACT_APP_API_URL;

// export function postCheckout(user_id = 0, product_ids = {}) {
//     return axios.post(
//         apiBaseUrl + '/api/create-invoice-link/' + user_id,
//         {
//             product_id_count: JSON.stringify(product_ids)
//         },
//         { headers: { 'Content-Type': 'application/json', Authorization: getAuthorizationToken() } }
//     );
// }

export function postCheckout(user_id = 0, checkout_data = {}) {
    return axios.post(
        apiBaseUrl + '/api/plan/create/' + user_id,
        {
            checkout_data: JSON.stringify(checkout_data)
        },
        { headers: { 'Content-Type': 'application/json', Authorization: getAuthorizationToken() } }
    );
}

export function getSubscriptionListing(user_id = 0) {
    return axios.get(apiBaseUrl + '/api/stripe/subscription/listing/'+user_id, 
    { headers: { 'Content-Type': 'application/json', Authorization: getAuthorizationToken() } }
    );
}

export function getSubscriptionListingPage(page_url='') {
    return axios.get(page_url, 
    { headers: { 'Content-Type': 'application/json', Authorization: getAuthorizationToken() } }
    );
}
