import React, { Component } from 'react';
import FormUserDetails from './FormUserDetails';
import FormPersonalDetails from './FormPersonalDetails';
import FormJobPositionDetails from './FormJobPositionDetails';
import FormPasswordDetails from './FormPasswordDetails';
import signup from '../services/signup.service';
import { Redirect } from 'react-router-dom';
import { resetSession } from '../services/session.service';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { initializeUpdateUser } from '../store/user/user.action';
import Button from '@material-ui/core/Button';

import amplitude from 'amplitude-js';


const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export class FormStepper extends Component {
	state = {
		step: 1,
		firstName: '',
		lastName: '',
		emailAddress: '',
		country: '',
		phoneNumber: '',
		jobPosition: '',
		password: '',
		confirmPassword: '',
		redirectToLogin: false,
		signupSuccess: false
	};

	componentWillMount() {
		resetSession();
	}

	// Proceed to next step
	nextStep = () => {
		const { step } = this.state;
		this.setState({
			step: step + 1
		});
	};

	// Go back to prev step
	prevStep = () => {
		const { step } = this.state;
		this.setState({
			step: step - 1
		});
	};

	// Handle fields change
	handleChange = (input) => (e) => {
		this.setState({ [input]: e.target.value });
	};

	handleSignup = () => {
		console.log(this.state);
		const userDemoCheckout = JSON.parse(localStorage.getItem('userDemoCheckout'))
		if (this.state.password.length === 0 || this.state.confirmPassword.length === 0) {
			alert('Required fields are not fullfilled');
			return;
		}
		if (this.state.password !== this.state.confirmPassword) {
			alert('Passwords did not match!');
			return;
		}
		this.setState({
			sendingSignup: true
		});
		signup({
			email_address: this.state.emailAddress,
			password: this.state.password,
			first_name: this.state.firstName,
			last_name: this.state.lastName,
			phone: this.state.phoneNumber,
			country: this.state.country,
			job_position: localStorage.getItem('industry') || String(userDemoCheckout.ind)
		})
		.then((response) => {
			console.log(response);
			// alert('Account created!');
			amplitude.getInstance().init(process.env.REACT_APP_APMPLITUDE_KEY);
			amplitude.getInstance().logEvent("USER: SIGN UP", {email: this.state.emailAddress}, () => {
				this.setState({ signupSuccess: true });
			});
		})
		.catch((error) => {
			console.log('signup error ',error);
			if (error && error.response && error.response.data) {
				alert(error.response.data.message || 'Error in signing up!');
			} else {
				alert('Error in signing up!');
			}
			// window.location.reload();
		});
	};
	redirecttoLogin = () => this.setState({ redirectToLogin: true });
	render() {
		const { step } = this.state;
		const {
			firstName,
			lastName,
			emailAddress,
			country,
			phoneNumber,
			jobPosition,
			password,
			confirmPassword
		} = this.state;
		const values = {
			firstName,
			lastName,
			emailAddress,
			country,
			phoneNumber,
			jobPosition,
			password,
			confirmPassword
		};

		if (this.state.redirectToLogin) {
			return <Redirect to="/login" />;
		}

		switch (step) {
			case 1:
				return <FormUserDetails nextStep={this.nextStep} handleChange={this.handleChange} values={values} />;
			case 2:
				return (
					<FormPersonalDetails
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						handleChange={this.handleChange}
						values={values}
					/>
				);
			case 3:
				
				return (
					<FormJobPositionDetails
						nextStep={this.nextStep}
						prevStep={this.prevStep}
						handleChange={this.handleChange}
						values={values}
					/>
				);
				
			case 4:
				return (
					<React.Fragment>
						<Dialog
							open={this.state.signupSuccess}
							TransitionComponent={Transition}
							keepMounted
							onClose={this.redirecttoLogin}
							aria-labelledby="alert-dialog-slide-title"
							aria-describedby="alert-dialog-slide-description"
						>
							<DialogTitle id="alert-dialog-slide-title">{'Account Registration'}</DialogTitle>
							<DialogContent>Success! Your account was created.</DialogContent>
							<DialogActions>
								<Button className="submit-btn" onClick={this.redirecttoLogin}>
									Go to Login
								</Button>
							</DialogActions>
						</Dialog>
						<FormPasswordDetails
							nextStep={this.nextStep}
							prevStep={this.prevStep}
							handleChange={this.handleChange}
							onFinishClicked={this.handleSignup}
							values={values}
						/>
					</React.Fragment>
				);
		}
	}
}

export default FormStepper;
