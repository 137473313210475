import * as types from './types';
import login from '../../services/login.service';
import { updateUser } from '../../services/userDetails.service';
import { verifySesion } from '../../services/session.service';

export function fetchOkay() {
	return { type: types.FETCH_OKAY };
}
export function fetchFail() {
	return { type: types.FETCH_FAIL };
}
export function disptachUpdateUser(payload) {
	return { type: types.UPADTE_USER, payload };
}
export function disptachUpdateUserFail(payload) {
	return { type: types.UPADTE_USER_FAIL, payload };
}
export function authenticate(payload) {
	return { type: types.AUTH_SUCCESS, payload };
}
export function authenticateFail(payload) {
	return { type: types.AUTH_FAIL, payload };
}

export function authSession() {
	return (dispatch) => {
		return verifySesion()
			.then((response) => {
				// console.log('verifySesion response', response);
				dispatch(authenticate({ ...response.data.user, authenticated: true }));
				return Promise.resolve(response);
			})
			.catch((err) => {
				dispatch(authenticateFail({ authenticated: false }));
				return Promise.reject(err);
			});
	};
}

export function verifyCredentials(email, password, role) {
	return (dispatch) => {
		return login({ email_address: email, password: password, user_role: role})
			.then((data) => {
				dispatch(fetchOkay(data.data));
				return Promise.resolve();
			})
			.catch((error) => {
				dispatch(fetchFail());
				if (error.response && error.response.data && error.response.data.message) {
					return Promise.reject(error.response.data.message);
				}
				return Promise.reject('Login Error');
			});
	};
}

export function initializeUpdateUser(userNewDetails = {}) {
	return (dispatch) => {
		return updateUser(userNewDetails)
			.then((response) => {
				dispatch(disptachUpdateUser(response.data.user));
				return Promise.resolve(response.data);
			})
			.catch((error) => {
				if (error.response && error.response.data && error.response.data.message) {
					dispatch(disptachUpdateUserFail(error.response.data.message));
					return Promise.reject(error.response.data.message);
				}
				dispatch(disptachUpdateUserFail(error));
				return Promise.reject(error);
			});
	};
}
