import React, { Component } from 'react';
import Logo from './assets/prospernav2-log.png';
import ShoppingCartLogo from './assets/shopping-cart-icon.png';
import MyProductsLogo from './assets/my-products-icon.png';
import LogoutLogo from './assets/exit.png';
import './Styles.css';
import Button from '@material-ui/core/Button';
import avatar from './assets/real-estate-agency-tb.png';
import { connect } from 'react-redux';
import { fetchProductsListings, fetchProductsListingsPage } from '../store/products/products.action';
import { Route, BrowserRouter, Redirect, Switch, withRouter } from 'react-router-dom';
import { postUserCheckout } from '../store/invoice/invoice.action';
import { TablePagination } from '@material-ui/core';
import InAppBanner from './InAppBanner';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ShoppingCartTab from './ShoppingCartTab';
import MyProductsTab from './MyProductsTab';
import CheckoutModal from './CheckoutModal';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import { getAuthorizationToken, userKey  } from '../services/session.service';
import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;



export class ShoppingCart extends Component {
	state = {
		isLoading: true,
		openSideNav: false,
		// for demo checkout only! 
		renderDemoCheckoutModal: false,
		showCheckoutModal: false,
		product_details: {},
		userDemoCheckout: {},
		setupTypes: [],
		seoTypes: []
	}
	componentDidMount() {
		//checks if user is checkout from demo sites
		this.setState({
			userDemoCheckout: JSON.parse(localStorage.getItem('userDemoCheckout'))
		}, () => {
			localStorage.setItem('!@GHsajd45', this.props.user.currency);
			if(this.state.userDemoCheckout != null){
				localStorage.removeItem('userDemoCheckout');
				this.getUserPromoCodes();
			}
			else{
				this.props.dispatch(fetchProductsListings(this.props.user.currency)).then((response) => {
					this.setState({
						
						isLoading: false,
					})
				});
			}
		});
	}
	getUserPromoCodes = () => {
        return axios
		.get(apiBaseUrl+'/api/user/promo-codes/get/'+localStorage.getItem(userKey), 
		{
			headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
		})
		.then(response => {
            console.log("response.data", response.data);
			this.setState({
                promoCodes: response.data.promo_codes,
			}, () => {
				this.getProductDetails(this.state.userDemoCheckout.pid);
			})
		});
	}
	getAdditionalServices = () => {
        return axios
        .get(apiBaseUrl+'/api/additional-services/get/'+localStorage.getItem(userKey), 
        {
            headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
        })
        .then(response => {
            if(response.data.status){
				this.setState({
					setupTypes: response.data.setup_types,
					seoTypes: response.data.seo_types,
				},() => {
					this.setState({
						renderDemoCheckoutModal: true,
						showCheckoutModal: !this.state.showCheckoutModal
					})
				})
            }
        })
        .catch(response => {
            this.setState({
                isStripeError: true,
                stripeErrMsg: response
            })
        });
    }
	getProductDetails = (id) => {
		return axios
		.post(apiBaseUrl+'/api/product/get/'+localStorage.getItem(userKey), 
		{
			product_id: id
        },{
			headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
		})
		.then(response => {
			this.setState({
				product_details: response.data.product_details,
			},() => {
				this.getAdditionalServices();
			})
		});
	}
	showCheckoutModal = () => {
		this.setState({showCheckoutModal: !this.state.showCheckoutModal});
	}
	executeCheckout = (checkoutData) => {
		this.setState({
			checkOutClicked: true,
		},() => {
			this.props.dispatch(postUserCheckout(checkoutData));
		})
	}
	handleChangePage = (event, page) => {
		const pagination_data = this.props.products.pagination_data;
		if ((pagination_data.current_page || 1) - 1 > page) {
			if (pagination_data.prev_page_url) {
				this.props.dispatch(fetchProductsListingsPage(pagination_data.prev_page_url)).then((response) => {
				});
			}
		} else if ((pagination_data.current_page || 1) - 1 < page) {
			if (pagination_data.next_page_url) {
				this.props.dispatch(fetchProductsListingsPage(pagination_data.next_page_url)).then((response) => {
				});
			}
		}
	};
	render() {
		let productsList = [];
		if (this.props.products && this.props.products.data) {
			productsList = this.props.products.data;
		}
		const pagination_data = this.props.products.pagination_data;
		console.log('productsList', productsList);
		if (this.props.invoice.data.url.length > 0) {
			window.location.href = this.props.invoice.data.url;
			return null;
		}
		// if (productsList.length === 0) {
		//     return <Redirect to='/my-products' />;
		// }
		// console.log('ShoppingCart will mount productsList', this.props.invoice);
		const classes = makeStyles((theme) => ({
			root: {
			  flexGrow: 1,
			},
			menuButton: {
			  marginRight: theme.spacing(2),
			},
			title: {
			  flexGrow: 1,
			},
		}));
		return (
			this.state.isLoading ? 
			<React.Fragment>
				{
					this.state.renderDemoCheckoutModal ?
						<CheckoutModal 
							currency={localStorage.getItem('!@GHsajd45')}
							setupTypes={this.state.setupTypes}
							seoTypes={this.state.seoTypes}
							promoCodes={this.state.promoCodes}
							demoCheckoutData={this.state.userDemoCheckout}
							demoCheckout={true}
							data={this.state.product_details} 
							showCheckoutModal={this.showCheckoutModal}
							executeCheckout={this.executeCheckout} 
							show={this.state.showCheckoutModal}
						/>
					:
					<p>Loading...</p>
				}
			</React.Fragment>
			:
			<div className="main-container">
				{
					window.screen.width < 992 ?
					<React.Fragment>
						<AppBar position="fixed">
							<Toolbar>
									<img alt="Prosperna" className="logo" src={Logo} width="140"/>
									<IconButton 
										edge="start" 
										className={classes.menuButton} 
										color="inherit" aria-label="menu" 
										onClick={() => {
											this.setState({
												...this.state,
												openSideNav:true
											})
										}}
									>
										<MenuIcon />
									</IconButton>
							</Toolbar>
						</AppBar>
						<SwipeableDrawer
							anchor={'right'}
							open={this.state.openSideNav}
							onClose={() => {
								this.setState({
									...this.state,
									openSideNav:false
								})
							}}
							onOpen={() => {
								this.setState({
									...this.state,
									openSideNav:true
								})
							}}
						>
							<List className="avatar-wrap">
								<Avatar>{this.props.user.first_name.charAt(0)+this.props.user.last_name.charAt(0)}</Avatar>
								<h5 className="text-center m-0 mt-1">{this.props.user.first_name+" "+this.props.user.last_name}</h5>
								<p className="text-center m-0">{this.props.user.email_address}</p>
								<p className="text-center m-0">{this.props.user.phone}</p>
							</List>
						
						
							<List>
								<ListItem button onClick={() => {window.location.href='/shopping-cart'}}>
									<ListItemIcon><img alt="shopping-cart-logo" src={ShoppingCartLogo} width="25px" /></ListItemIcon>
									<ListItemText className="font-bold" primary={'Shopping Cart'} />
								</ListItem>
								<ListItem button onClick={() => {window.location.href='/my-products'}}>
									<ListItemIcon><img alt="shopping-cart-logo" src={MyProductsLogo} width="25px" /></ListItemIcon>
									<ListItemText primary={'My Products'} />
								</ListItem>
							</List>
							<Divider />
							<List>
								<ListItem button onClick={() => {window.location.href='/login'}}>
									<ListItemIcon><img alt="shopping-cart-logo" src={LogoutLogo} width="25px" /></ListItemIcon>
									<ListItemText className="font-bold" primary={'Logout'} />
								</ListItem>
							</List>
						</SwipeableDrawer>
						
						<div className="shopping-cart-header">
							<div className="container">
								<h2>Select your theme.</h2>
							</div>
						</div>
					</React.Fragment>
					:
					<React.Fragment>
						<div className="main-header">
							<div className="container">
								<div className="row">
									<div className="col-lg-6">
										<div className="header-logo">
											<img alt="Prosperna" className="logo" src={Logo} />
										</div>
									</div>
									<div className="col-lg-6">
										<div className="header-nav">
											<Button href="/shopping-cart">
												<span><img alt="shopping-cart-logo" src={ShoppingCartLogo} /></span>
												Shopping Cart
											</Button>
											<Button href="/my-products">
												<span><img alt="shopping-cart-logo" src={MyProductsLogo} /></span>
												My Products
											</Button>
											<Button className="logout-btn" href="/login">
												Logout
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div> 
						<div className="shopping-cart-header">
							<div className="container">
								<h1>Select your theme.</h1>
							</div>
						</div>
					</React.Fragment>
				}
				{/* <InAppBanner /> */}
				<div className="container">
					<ShoppingCartTab />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	// console.log('fetchProductsListings response', state);
	return {
		products: { ...state.productsReducer },
		invoice: { ...state.invoiceReducer },
		user: { ...state.userReducer },
	};
}

export default connect(mapStateToProps)(ShoppingCart);


