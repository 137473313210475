import React, { Component } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import login from '../services/login.service';
import { Redirect } from 'react-router-dom';
import { resetSession } from '../services/session.service';
import { verifyCredentials } from '../store/user/user.action';
import { connect } from 'react-redux';
import { forgotPassword } from '../services/userDetails.service';
import amplitude from 'amplitude-js';
export class Login extends Component {
	constructor() {
		super();
		this.state = {
			email: '',
			password: '',
			role: '1',
			errorMessage: undefined
		};
	}

	componentWillMount() {
		resetSession();
	}

	handleChange = (input) => (e) => {
		this.setState({ [input]: e.target.value });
	};

	initializeLogin() {
		// dispatch a redux action for credential verification then navitage to /shopping-cart
		this.props.dispatch(verifyCredentials(this.state.email, this.state.password, this.state.role)).then(
			() => {
				this.setState({ errorMessage: undefined });
				amplitude.getInstance().init(process.env.REACT_APP_APMPLITUDE_KEY);
				amplitude.getInstance().logEvent("USER: LOGIN", {email: this.state.email}, () => {
					if(localStorage.getItem('redirect') == 'my-products'){
						this.props.history.push('/my-products');
						localStorage.removeItem('redirect');
					}
					else{
						this.props.history.push('/shopping-cart');
					}
				});
				
			},
			(error) => {
				this.setState({ errorMessage: error });
				console.log('setstate error', error);
			}
		);
	}

	render() {
		if (this.state.authenticated) {
			return <Redirect to="/shopping-cart" />;
		}
		return (
			<div className="form-container">
				<header className="form-header">
					<img className="logo" alt="Prosperna" src={Logo} />
				</header>

				<div className="login-form-description">
					<h1>LOG IN</h1>
					<p>Websites made easy.</p>
				</div>
				{
					this.state.errorMessage != undefined ?
					<div
						className="error-container"
					>
						<div className="error-icon">
							<ErrorIcon color="error" />
						</div>
						<div className="error-txt">
							{this.state.errorMessage && this.state.errorMessage.length > 0 ? (
								<p className="signup-error-txt">{this.state.errorMessage}</p>
							) : (
								<p className="signup-error-txt">
									Log in failed. Username or Password might be incorrect.
									<br />
									Try again or click "forgot your password."
								</p>
							)}
						</div>
					</div>
					:
					<React.Fragment>
						<br/>
						<br/>
					</React.Fragment>
				}
				
				<div className="error-container" style={{ display: 'none' }}>
					<div className="error-icon">
						<ErrorIcon color="error" />
					</div>
					<div className="error-txt">
						<p>Please fill in Username and Password</p>
					</div>
				</div>
				<div className="success-container" style={{ display: 'none' }}>
					<div className="success-icon">
						<CheckCircleIcon style={{ color: '#2ca94f' }} />
					</div>
					<div className="success-txt">
						<p>Success. Please wait for redirection.</p>
					</div>
				</div>

				<form className="form-fields">
					<div className="form-row">
						<TextField
							onChange={this.handleChange('email')}
							label="Email Address"
							variant="outlined"
							className="input-txt"
						/>
					</div>

					<div className="form-row">
						<TextField
							onChange={this.handleChange('password')}
							label="Password"
							variant="outlined"
							type="password"
							className="input-txt"
						/>
					</div>

					<div className="policy-txt" style={{ visibility: 'hidden' }}>
						<div>
							By clicking "SET UP MY BILLING", you agree to the{' '}
							<Link
								className="terms-of-service-link"
								href="https://app.prosperna.com/web/terms-and-condition"
								target="_blank"
							>
								Terms of Service
							</Link>,
							<br />
							<Link
								className="terms-of-service-link"
								href="https://app.prosperna.com/web/privacy-policy"
								target="_blank"
							>
								Data Privacy and Spam Policy
							</Link>.
						</div>
					</div>
				</form>
				<div className="login-stepper-btn-container">
					{/* <Button className="login-btn" href="/shopping-cart">
                        Login
					</Button> */}
					<Button
						className="login-btn"
						onClick={() => {
							this.initializeLogin();
						}}
					>
						Login
					</Button>
					<div>
						<ForgotPassword />
						<div>
							<p>Not using Prosperna? <a href="https://www.prosperna.com/request-for-a-free-demo/" target="_blank">Book a demo today!</a></p>
						</div>
					</div>
				</div>

				<div className="footer-container">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	console.log('state', state);
	return {
		credentials: { ...state }
	};
}
export default connect(mapStateToProps)(Login);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export function ForgotPassword() {
	const [ open, setOpen ] = React.useState(false);
	const [ email, setEmail ] = React.useState('');

	const handleClickOpen = (e) => {
		e.preventDefault();
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleForgotPassword = () => {
		forgotPassword(email).then((response) => {
			// console.log('response', response.data);
			alert(response.data.message);
		});
		handleClose();
	};

	return (
		<div>
			<p>Forgot your <a href="/" onClick={handleClickOpen.bind(this)}>password?</a></p>
			<Dialog
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description"
			>
				<DialogTitle id="alert-dialog-slide-title">{'Forgot Password'}</DialogTitle>
				<DialogContent>
					<form className="form-fields-modal">
						{/* <DialogContentText id='alert-dialog-slide-description' /> */}
						<TextField
							value={email || ''}
							onChange={(event) => {
								setEmail(event.target.value);
							}}
							className="hover-field"
							label="Email Address"
							fullWidth
							variant="outlined"
						/>
					</form>
				</DialogContent>
				<DialogActions>
					<Button className="cancel-btn" onClick={handleClose}>
						Cancel
					</Button>
					<Button className="submit-btn" onClick={handleForgotPassword}>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
