import axios from 'axios';
import { getAuthorizationToken } from '../services/session.service';
const apiBaseUrl = process.env.REACT_APP_API_URL;

export function getProductsLising(user_id = 0, currency_id = null) {
    return axios.get(apiBaseUrl + '/api/products/'+user_id+'/'+currency_id, 
    { headers: { 'Content-Type': 'application/json', Authorization: getAuthorizationToken() } }
    );
}

export function getProductsLisingPage(url='') {
    return axios.get(url, 
    { headers: { 'Content-Type': 'application/json', Authorization: getAuthorizationToken() } }
    );
}