import React, { Component } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ErrorIcon from '@material-ui/icons/Error';

export class Suspension extends Component {
	render() {
		return (
			<div className="suspension-container">
				<header className="suspension-header">
					<img className="logo" alt="Prosperna" src={Logo} />
				</header>

				<div className="suspension-txt">
					<h1>Website temporarily unavailable.</h1>
					<p>Please contact support@prosperna.com for more information.</p>
				</div>

				<div className="footer-container">
					<p>
						© {new Date().getFullYear()} Prosperna. All rights reserved.
					</p>
				</div>
			</div>
		);
	}
}

export default Suspension;
