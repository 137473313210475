import React, { Component } from 'react';
import { Modal, Button, Form, Col, InputGroup } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import AddIcon from '../assets/add-icon.png';
import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;

class PromoCodeModal extends Component {
    state = { 
        showModal: false,
        validated: false,
        isSelectedUser: true,
        isAllUser: false,
        promo_code: "",
        month_duration: 0,
        email: [],
        discount: "10",
        expiration_date: new Date(),
        email_field_name: ["email1"],
        email_field_values: { email1: "" },
        isDurationMonths: true,
        discount_type: "discount-percentage",
        discount_flat_currency: "php"
    }
    processRequest() {
        return axios
        .post(apiBaseUrl+'/api/insert-promo-code', 
        {
            isSelectedUser: this.state.isSelectedUser,
            isAllUser: this.state.isAllUser,
            promo_code: this.state.promo_code,
            month_duration: this.state.month_duration,
            discount: this.state.discount,
            expiration_date: moment(this.state.expiration_date).format("YYYY-MM-DD"),
            email_field_values: this.state.email_field_values,
            id: localStorage.getItem("userKey"),
            discount_type: this.state.discount_type,
            discount_flat_currency: this.state.discount_flat_currency,
        },
		{
			headers: {'Content-Type':'application/json'}
		})
		.then(response => {
            if(response.data.status){
                this.props.getPromoCodes();
                this.setState({
                    showModal: false
                })
            }
            else{
                alert(response.data.message)
            }
		});
    }
    formSelectedUser = (validated, handleSubmit, handleExpirationChange, handleInputChange, handleDurationOnChange) => {
        const handleAddEmail = () => {
            const newFieldName = String("email"+(this.state.email_field_name.length+1));
            const updatedArrayFieldName = [...this.state.email_field_name];
            updatedArrayFieldName.push(newFieldName);
            const updatedObjFieldValues = this.state.email_field_values;
            updatedObjFieldValues[newFieldName] = "";
            this.setState({
                email_field_name: updatedArrayFieldName,
                email_field_values: updatedObjFieldValues
            })
        };
        const handleChangeEmail = (e) => {
            this.setState({
                ...this.state,
                email_field_values: {
                    ...this.state.email_field_values,
                    [e.target.name]: e.target.value,
                }
            })
        }
        const handleFlatDiscountCurrencyChange = (e) => {
            this.setState({
                ...this.state,
                discount_flat_currency: e.target.id,
            })
        }
        const handleDiscountTypeChange = (e) => {
            this.setState({
                ...this.state,
                discount_type: e.target.id,
            })
        }
        return(
            <Form noValidate validated={validated} onSubmit={handleSubmit.bind(this)}>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                        <Form.Label>Promo Code</Form.Label>
                        <Form.Control type="text" name="promo_code" onChange={handleInputChange.bind(this)} required />
                        <Form.Control.Feedback type="invalid">
                            Please enter a Promo Code.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                        <Form.Label>Customer Email <span onClick={handleAddEmail}><img className="add-email-icon" src={AddIcon} /></span></Form.Label>
                        {
                            this.state.email_field_name.map((input, index) => 
                                <Form.Control 
                                    type="email" 
                                    className={this.state.email_field_name.length === 1 ? "" : "mb-1"} 
                                    name={input} 
                                    onChange={handleChangeEmail.bind(this)} 
                                    required 
                                />
                            )
                        }
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid Customer Email.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <Form.Label>Discount Type</Form.Label>
                        <Form.Check 
                            type="radio"
                            id={`discount-percentage`}
                            label={`Percentage discount`}
                            onChange={handleDiscountTypeChange.bind(this)} 
                            checked={this.state.discount_type == "discount-percentage" ? true : false}
                        />
                        <Form.Check 
                            type="radio"
                            id={`discount-flat`}
                            label={`Flat amount discount`}
                            onChange={handleDiscountTypeChange.bind(this)} 
                            checked={this.state.discount_type == "discount-flat" ? true : false}
                        />
                    </Form.Group>
                    {
                        this.state.discount_type == "discount-flat" ? 
                        <React.Fragment>
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Currency</Form.Label>
                                <Form.Check 
                                    type="radio"
                                    id={`php`}
                                    label={`PHP`}
                                    onChange={handleFlatDiscountCurrencyChange.bind(this)} 
                                    checked={this.state.discount_flat_currency == "php" ? true : false}
                                />
                                <Form.Check 
                                    type="radio"
                                    id={`usd`}
                                    label={`USD`}
                                    onChange={handleFlatDiscountCurrencyChange.bind(this)} 
                                    checked={this.state.discount_flat_currency == "usd" ? true : false}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Discount amount</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend">{this.state.discount_flat_currency == "php" ? "₱" : "$"}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        name="discount"
                                        onChange={handleInputChange.bind(this)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid Discount.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </React.Fragment>
                        :
                        <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Label>Percentage off</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="number"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    name="discount"
                                    onChange={handleInputChange.bind(this)}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid Discount.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    }
                    
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="validationCustom04">
                        <Form.Label>Duration</Form.Label>
                        {/* <Form.Control type="number" name="month_duration" onChange={handleInputChange.bind(this)} required />
                        <Form.Control.Feedback type="invalid">
                            Please enter Month Duration.
                        </Form.Control.Feedback> */}
                        <Form.Control as="select" name="month_duration" onChange={handleDurationOnChange.bind(this)} defaultValue="months">
                            <option value="months">Months</option>
                            <option value="forever">Forever</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        {
                            this.state.isDurationMonths ?
                            <React.Fragment>
                                <Form.Label>Months Duration</Form.Label>
                                <Form.Control type="number" name="month_duration" onChange={handleInputChange.bind(this)} required />
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid Months Duration.
                                </Form.Control.Feedback>
                            </React.Fragment>
                            :
                            null
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    
                    <Form.Group as={Col} md="12" controlId="validationCustom05">
                        <Form.Label>Valid Until</Form.Label>
                        {/* <Form.Control type="date" required /> */}
                        <DatePicker
                            name="expiration_date"
                            className="form-control"
                            selected={this.state.expiration_date}
                            onChange={handleExpirationChange.bind(this)}
                            dateFormat="yyyy-MM-dd"
                            minDate={new Date()}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a Expiration Date.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <center>
                    <Button type="submit" className="func-btn" variant="success">Save</Button>
                </center>
            </Form>
        );
    }
    formAllUser = (validated, handleSubmit, handleExpirationChange, handleInputChange, handleDurationOnChange) => {
        const handleFlatDiscountCurrencyChange = (e) => {
            this.setState({
                ...this.state,
                discount_flat_currency: e.target.id,
            })
        }
        const handleDiscountTypeChange = (e) => {
            this.setState({
                ...this.state,
                discount_type: e.target.id,
            })
        }
        return(
            <Form noValidate validated={validated} onSubmit={handleSubmit.bind(this)}>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <Form.Label>Promo Code</Form.Label>
                        <Form.Control name="promo_code" type="text" onChange={handleInputChange.bind(this)} required />
                        <Form.Control.Feedback type="invalid">
                            Please enter a Promo Code.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <Form.Label>Discount</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type="number"
                                aria-describedby="inputGroupPrepend"
                                required
                                name="discount"
                                onChange={handleInputChange.bind(this)}
                            />
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid Discount.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group> */}
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                        <Form.Label>Discount Type</Form.Label>
                        <Form.Check 
                            type="radio"
                            id={`discount-percentage`}
                            label={`Percentage discount`}
                            onChange={handleDiscountTypeChange.bind(this)} 
                            checked={this.state.discount_type == "discount-percentage" ? true : false}
                        />
                        <Form.Check 
                            type="radio"
                            id={`discount-flat`}
                            label={`Flat amount discount`}
                            onChange={handleDiscountTypeChange.bind(this)} 
                            checked={this.state.discount_type == "discount-flat" ? true : false}
                        />
                    </Form.Group>
                    {
                        this.state.discount_type == "discount-flat" ? 
                        <React.Fragment>
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Currency</Form.Label>
                                <Form.Check 
                                    type="radio"
                                    id={`php`}
                                    label={`PHP`}
                                    onChange={handleFlatDiscountCurrencyChange.bind(this)} 
                                    checked={this.state.discount_flat_currency == "php" ? true : false}
                                />
                                <Form.Check 
                                    type="radio"
                                    id={`usd`}
                                    label={`USD`}
                                    onChange={handleFlatDiscountCurrencyChange.bind(this)} 
                                    checked={this.state.discount_flat_currency == "usd" ? true : false}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Discount amount</Form.Label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="inputGroupPrepend">{this.state.discount_flat_currency == "php" ? "₱" : "$"}</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control
                                        type="text"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        name="discount"
                                        onChange={handleInputChange.bind(this)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter a valid Discount.
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </React.Fragment>
                        :
                        <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Label>Percentage off</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="number"
                                    aria-describedby="inputGroupPrepend"
                                    required
                                    name="discount"
                                    onChange={handleInputChange.bind(this)}
                                />
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="inputGroupPrepend">%</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid Discount.
                                </Form.Control.Feedback>
                            </InputGroup>
                        </Form.Group>
                    }
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} controlId="validationCustom04">
                        <Form.Label>Duration</Form.Label>
                        {/* <Form.Control type="number" name="month_duration" onChange={handleInputChange.bind(this)} required />
                        <Form.Control.Feedback type="invalid">
                            Please enter Month Duration.
                        </Form.Control.Feedback> */}
                        <Form.Control as="select" name="month_duration" onChange={handleDurationOnChange.bind(this)} defaultValue="months">
                            <option value="months">Months</option>
                            <option value="forever">Forever</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                        {
                            this.state.isDurationMonths ?
                            <React.Fragment>
                                <Form.Label>Months Duration</Form.Label>
                                <Form.Control type="number" name="month_duration" onChange={handleInputChange.bind(this)} required />
                                <Form.Control.Feedback type="invalid">
                                    Please enter valid Months Duration.
                                </Form.Control.Feedback>
                            </React.Fragment>
                            :
                            null
                        }
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group as={Col} md="12" controlId="validationCustom04">
                        <Form.Label>Valid Until</Form.Label>
                        {/* <Form.Control type="date" required /> */}
                        <DatePicker
                            className="form-control"
                            selected={this.state.expiration_date}
                            onChange={handleExpirationChange.bind(this)}
                            dateFormat="yyyy-MM-dd"
                            minDate={new Date()}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter a Expiration Date.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
                <center>
                    <Button type="submit" className="func-btn" variant="success">Save</Button>
                </center>
            </Form>
        );
    }
    
    render() { 
        const handleModalBehavior = () => this.setState({ showModal: !this.state.showModal });
        const validated = this.state.validated;
        const handleSubmit = (event) => {
            event.preventDefault();
            const form = event.currentTarget;
            if (form.checkValidity() === false) {
                event.stopPropagation();
                this.setState({
                    validated:true
                })
            }
            else{
                if(this.state.discount_type == "discount-flat"){
                    if(this.state.month_duration != "forever"){
                        if(Number.isInteger(parseFloat(this.state.month_duration))){
                            if(parseFloat(this.state.month_duration) > 0){
                                this.processRequest();
                            }else{
                                alert('Invalid Month Duration!');
                            }
                        }else{
                            alert('Invalid Month Duration!');
                        }
                    }else{
                        this.processRequest();
                    }
                }else{
                    if(Number.isInteger(parseFloat(this.state.discount))){
                        if(parseFloat(this.state.discount) > 0 && parseFloat(this.state.discount) <= 100){
                            if(this.state.month_duration != "forever"){
                                if(Number.isInteger(parseFloat(this.state.month_duration))){
                                    if(parseFloat(this.state.month_duration) > 0){
                                        this.processRequest();
                                    }else{
                                        alert('Invalid Month Duration!');
                                    }
                                }else{
                                    alert('Invalid Month Duration!');
                                }
                            }else{
                                this.processRequest();
                            }
                        }else{
                            alert('Invalid Discount!');
                        }
                    }else{
                        alert('Invalid Discount!');
                    }
                }
                
            }
        };
        const handleDurationOnChange = (event) => {
            if(event.target.value == "months"){
                this.setState({
                    isDurationMonths: true
                })
            }
            else{
                this.setState({
                    isDurationMonths: false,
                    month_duration: "forever"
                })
            }
        }
        const handleInputChange = (event) => {
            this.setState({
                [event.target.name]: event.target.value
            })
        }
        const handleExpirationChange = (event) => {
            this.setState({expiration_date: event});
        }
        return ( 
            <React.Fragment>
                <Button className="func-btn" variant="success" onClick={handleModalBehavior}>Add Promo Code(s)</Button>
                <Modal id="promo-code-modal" show={this.state.showModal} onHide={handleModalBehavior}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Promo Code</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    <Form.Check type="radio" id="radio1">
                                        <Form.Check.Input type="radio" onClick={() => {this.setState({isSelectedUser: !this.state.isSelectedUser, isAllUser: !this.state.isAllUser})}} checked={this.state.isSelectedUser}/>
                                        <Form.Check.Label>For selected user</Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom02">
                                    <Form.Check type="radio" id="radio2">
                                        <Form.Check.Input type="radio" onClick={() => {this.setState({isAllUser: !this.state.isAllUser, isSelectedUser: !this.state.isSelectedUser})}} checked={this.state.isAllUser}/>
                                        <Form.Check.Label>For all users</Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        {
                            this.state.isSelectedUser ?
                                this.formSelectedUser(validated, handleSubmit, handleExpirationChange, handleInputChange, handleDurationOnChange)
                            :
                            this.state.isAllUser ?
                                this.formAllUser(validated, handleSubmit, handleExpirationChange, handleInputChange, handleDurationOnChange)
                            :
                            null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {/* <Button variant="secondary" onClick={handleModalBehavior}>
                            Close
                        </Button>
                        <Button className="func-btn" variant="success" onClick={handleModalBehavior}>
                            Save Changes
                        </Button> */}
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

export default PromoCodeModal;