import React, { Component, Fragment } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import Button from '@material-ui/core/Button';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';
import { getAuthorizationToken, userKey  } from '../services/session.service';
import $ from 'jquery';

import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;



export class PaidInvoice extends Component {
	constructor() {
		super();
		this.state = {
			pdfButtonVisibility: true,
			invoiceDetails: [],
			userDetails: [],
			expirationDate: "",
			isNoData: false,
			isLoading: true,
		};
	}
	componentDidMount() {
		const arrayURL = window.location.href.split("/");
		this.getPlanInvoiceDetails(arrayURL);
	}
	getInvoiceNumber = (arrayURL) => {
		const invoiceNumber = arrayURL[arrayURL.length - 1];
		return invoiceNumber;
	}
	getPlanInvoiceDetails = (arrayURL) => {
		return axios
		.post(apiBaseUrl+'/api/plan/invoice/'+localStorage.getItem(userKey), 
		{
			invid: this.getInvoiceNumber(arrayURL) 
        },{
			headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
		})
		.then(response => {
			this.setState({
				invoiceDetails: response.data,
				isLoading:false
			})
		})
		.catch((ex) => {
			this.setState({
				isNoData: true,
				isLoading:false
			})
		});
		// return axios
		// .get(apiBaseUrl+'/invoice-details?invoice_num='+this.getInvoiceNumber(arrayURL), 
		// {
		// 	headers: {'Content-Type':'application/json'}
		// })
		// .then(response => {
		// 	if(response.data.length > 0){
		// 		this.setState({
		// 			invoiceDetails: response.data,
		// 		})
		// 		this.getExpirationDate(arrayURL);
		// 	}
		// 	else{
		// 		this.setState({
		// 			isNoData: true,
		// 			isLoading2: false
		// 		})
		// 	}
		// });
	}
	createAndDownloadPdf = (e) => {
		e.preventDefault();
		this.setState({
			pdfButtonVisibility: false
		});

		$('.invoice-pdf button').css('color', '#fff');
		const source = document.getElementById('invoice-pdf-container');

		var newWindow = window.open();

		html2canvas(source).then((canvas) => {
			var imgData = canvas.toDataURL('image/png');
			var pdf = new jsPDF('p', 'px', 'a4');
			const imgProps = pdf.getImageProperties(imgData);
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = imgProps.height * pdfWidth / imgProps.width;
			pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
			pdf.save('invoice.pdf');
		});

		this.setState({
			pdfButtonVisibility: true
		});
		$('.invoice-pdf button').css('color', '#000');

		setTimeout(function() {
			newWindow.close();
		}, 50);
		return false;
	};
	reformatDate = (date) => {
		const moment = require('moment');
		return("moment(date)",moment(date.split(" ")[0]).format('MMMM DD, YYYY')) 
	}
	render() {
		const details = this.state.invoiceDetails;
		return (
			this.state.isLoading ? 
			<p>Loading...</p>
			:
			this.state.isNoData ?
			<p>No data found :(</p>
			:
			<Fragment>
				<div id="invoice-pdf-container">
					<div className="main-header">
						<div className="header-logo">
							<img alt="Prosperna" className="logo" src={Logo} />
						</div>
					</div>
					<div className="invoice-container">
						<div className="invoice-desc-pdf">
							<div className="invoice-desc">
								<p>Invoice #{details.invoice_number} - {this.reformatDate(details.created_at)} </p>
							</div>
							{this.state.pdfButtonVisibility ? (
								<div className="invoice-pdf">
									<Button className="pdf-btn" onClick={this.createAndDownloadPdf.bind(this)}>
										<PictureAsPdfIcon /> PDF
									</Button>
								</div>
							) : null}
						</div>
						<div className="invoice-payment-desc">
							<div className="inv-payment-from">
								<p>
									<span className="payment-txt">Payment From:</span>
									<br />
									<span className="payment-name">{details.user.first_name+" "+details.user.last_name}</span>
									<br />{details.user.email_address}<br />{details.user.phone}
								</p>
							</div>
							<div className="inv-payment-to">
								<p>
									<span className="payment-txt">Payment To:</span>
									<br />
									<span className="payment-name">Prosperna</span>
									<br />https://www.prosperna.com/<br />0917 659 1948
								</p>
							</div>
						</div>
						<div className="invoice-details-container">
							<div className="invoice-details-content">
								<div className="invoice-row-1">
									<div className="invoice-status">
										<div className="invoice-txt">Invoice</div>
										<div className="fade-txt subtxt">Invoice #</div>
										<div className="fade-txt subtxt">Billed On</div>
										{/* <div className="fade-txt subtxt">Transaction Type</div>
										<div className="fade-txt subtxt">Plan Type</div> */}
										<div className="fade-txt subtxt">Status</div>
									</div>
									<div className="invoice-desc">
										<div className="invoice-txt" style={{ visibility: 'hidden' }}>
											Invoice
										</div>
										<div className="subtxt">{details.invoice_number}</div>
										<div className="subtxt">{this.reformatDate(details.created_at)}</div>
										{/* <div className="subtxt">Lorem ipsum</div>
										<div className="subtxt">Lorem</div> */}
										<div className="subtxt">
											<Button className="paid-btn">Paid</Button>
										</div>
									</div>
								</div>
								<div className="invoice-row-2">
									<div className="invoice-due">
										<table className="dueDate-tbl">
											<tbody>
												<tr>
													<th className="highlight">NEXT DUE DATE</th>
													<th>{this.reformatDate(details.expiry_date)}</th>
												</tr>
												<tr>
													<td
														colSpan="2"
														align="center"
														className="amount custom-amount"
														data-amount="1"
													>
														{details.formatted_total_amount}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
							<table id="invoice-desc">
								<tbody>
									<tr>
										<th width="30%">Current Payment Date</th>
										<th>Details</th>
										<th width="15%">Amount</th>
										<th width="15%">Subtotal</th>
									</tr>
									<tr>
										<td>{this.reformatDate(details.created_at)}</td>
										<td>{details.product.product_name+" "+details.product.description}</td>
										<td className="amount" data-amount="1">
											{details.formatted_total_amount}
										</td>
										<td className="amount" data-amount="1">
											{details.formatted_total_amount}
										</td>
									</tr>
									<tr>
										<td />
										<td />
										<td>Subtotal</td>
										<td className="sub-total-amount amount" data-amount="1">
											{details.formatted_total_amount}
										</td>
									</tr>
									<tr className="greyfill">
										<td />
										<td />
										<td className="total">Credits</td>
										<td className="credits total" data-amount="0">
											Php 0
										</td>
									</tr>
									<tr className="greyfill">
										<td />
										<td />
										<td className="due">TOTAL</td>
										<td className="grand-total-amount due">{details.formatted_total_amount}</td>
									</tr>
								</tbody>
							</table>
							<div className="transaction-info">
								<h2>Transactions</h2>
								<table className="table table-striped">
									<thead>
										<tr>
											<th>Payment Date</th>
											<th>Payment Method</th>
											<th>Transaction ID</th>
											<th>Amount</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{this.reformatDate(details.updated_at)}</td>
											<td>Paypal</td>
											<td>Invoice #{details.invoice_number}</td>
											<td data-amount="0" className="transaction-grand-total">
												{details.formatted_total_amount}
											</td>
										</tr>
										{/* <tr>
											<td colSpan="3" className="balance-td">
												Balance
											</td>
											<td className="balance-td-amount">0</td>
										</tr> */}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default PaidInvoice;
