import React, { Component } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import Button from '@material-ui/core/Button';
import avatar from './assets/real-estate-agency-tb.png';
import { connect } from 'react-redux';
import { fetchProductsListings, fetchProductsListingsPage } from '../store/products/products.action';
import { Route, BrowserRouter, Redirect, Switch, withRouter } from 'react-router-dom';
import { postUserCheckout } from '../store/invoice/invoice.action';
import { TablePagination } from '@material-ui/core';
import InAppBanner from './InAppBanner';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Spinner, Modal, Form, Row, Col} from 'react-bootstrap';

import CheckoutModal from './CheckoutModal';

import { getAuthorizationToken, userKey  } from '../services/session.service';
import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;

export class ShoppingCartTab extends Component {
	state = {
		showCheckoutBtn: false,
		hoveredId: '',
		checkOutClicked: false,
		promoCodes: [],
		isLoading: true,
		showCheckoutModal: false,
		
		setupTypes: [],
		seoTypes: []
	}
	componentDidMount() {
		this.getUserPromoCodes();
	}
	
	handleChangePage = (event, page) => {
		const pagination_data = this.props.products.pagination_data;
		if ((pagination_data.current_page || 1) - 1 > page) {
			if (pagination_data.prev_page_url) {
				this.props.dispatch(fetchProductsListingsPage(pagination_data.prev_page_url)).then((response) => {
				});
			}
		} else if ((pagination_data.current_page || 1) - 1 < page) {
			if (pagination_data.next_page_url) {
				this.props.dispatch(fetchProductsListingsPage(pagination_data.next_page_url)).then((response) => {
				});
			}
		}
	};
	showCheckoutBtn(id) {
		this.setState({
			showCheckoutBtn: true,
			hoveredId: id
		})
	} 
	hideCheckoutBtn(id) {
		if(!this.state.showCheckoutModal){
			this.setState({
				showCheckoutBtn: false,
				hoveredId: ''
			})
		}
		
	} 
	showCheckoutModal = () => {
		this.setState({showCheckoutModal: !this.state.showCheckoutModal});
	}
	getUserPromoCodes = () => {
        return axios
		.get(apiBaseUrl+'/api/user/promo-codes/get/'+localStorage.getItem(userKey), 
		{
			headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
		})
		.then(response => {
			this.getAdditionalServices();
			this.setState({
                promoCodes: response.data.promo_codes,
			})
		});
	}
	getAdditionalServices = () => {
        return axios
        .get(apiBaseUrl+'/api/additional-services/get/'+localStorage.getItem(userKey), 
        {
            headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
        })
        .then(response => {
            if(response.data.status){
				this.setState({
					setupTypes: response.data.setup_types,
					seoTypes: response.data.seo_types,
					isLoading: false
				})
            }
        })
        .catch(response => {
            this.setState({
                isStripeError: true,
                stripeErrMsg: response
            })
        });
    }
	executeCheckout = (checkoutData) => {
		this.setState({
			checkOutClicked: true,
		},() => {
			this.props.dispatch(postUserCheckout(checkoutData));
		})
	}
	currencyChange = (e) => {
		// alert(e.target.value)
		localStorage.setItem('!@GHsajd45', e.target.value);
		this.setState({
			isLoading: true,
		})
		this.props.dispatch(fetchProductsListings(e.target.value)).then((response) => {
			this.getAdditionalServices();
		});
	}
	render() {
		let productsList = [];
		if (this.props.products && this.props.products.data) {
			productsList = this.props.products.data;
		}
		const pagination_data = this.props.products.pagination_data;
		if (this.props.invoice.data.url.length > 0) {
			window.location.href = this.props.invoice.data.url;
			return null;
		}
		return (
			this.state.checkOutClicked || this.state.isLoading?
			<React.Fragment>
				<div className="mt-5 mb-3">
					<Spinner animation="grow" variant="danger" />
					<Spinner animation="grow" variant="warning" />
					<Spinner animation="grow" variant="success" />
				</div>
				<div>
					<p>Please wait...</p>
				</div>
			</React.Fragment>
			:
			<React.Fragment>
			<div className="shopping-cart-container">
				<div className="shopping-cart-overflow">
			
					{productsList.length === 0 ? (
						<h1>No Products Available</h1>
					) : (
						<React.Fragment>
							{
								this.props.user.website_subcription_count == 0 ?
									<div className="text-left">
										<label for="currency-picker">Display prices in:</label>
										<select name="" id="currency-picker" style={{ width: '100px', marginLeft: '10px'}} onChange={this.currencyChange.bind(this)} defaultValue={localStorage.getItem('!@GHsajd45')}>
											<option value="1">USD</option>
											<option value="2">PHP</option>
										</select>
									</div>
								:
									null
							}
							
							<TablePagination
								rowsPerPageOptions={[]}
								component="div"
								count={pagination_data.total || 0}
								rowsPerPage={pagination_data.per_page || 0}
								page={(pagination_data.current_page || 1) - 1}
								onChangePage={this.handleChangePage}
							/>
							<div id="products-menu">
								<div className="row">
									{productsList.map((data, index) => (
										<div className="col-lg-4 mb-4">
											<div className="product-wrap">
												<div className={this.state.showCheckoutBtn && this.state.hoveredId === "product"+data.product_id ? "product-thumbnail product-thumbnail-hover" : "product-thumbnail"} onMouseEnter={()=>{this.showCheckoutBtn("product"+data.product_id)}} onMouseLeave={()=>{this.hideCheckoutBtn("product"+data.product_id)}}>
													{
														this.state.showCheckoutBtn && this.state.hoveredId === "product"+data.product_id ? 
															<CheckoutModal 
																currency={localStorage.getItem('!@GHsajd45')}
																promoCodes={this.state.promoCodes}
																setupTypes={this.state.setupTypes}
																seoTypes={this.state.seoTypes}
																demoCheckout={false}
																data={data} 
																showCheckoutModal={this.showCheckoutModal}
																executeCheckout={this.executeCheckout} 
																show={this.state.showCheckoutModal}
															/>
														:
															null
													}
													<img 
														className={this.state.showCheckoutBtn && this.state.hoveredId === "product"+data.product_id ? "product-thumbnail-img-hover" : null}
														src={data.product_thumbnail_link || Logo}
														height="333.15"
													/>
												</div>
												<div className="product-info">
													<div className="product-name">
														<p>{data.product_name}</p>
													</div>
													<div className="product-description">
														<p>{data.description}</p>
													</div>
													<div className="product-price">
														{/* <p>{data.formatted_amount}</p> */}
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						</React.Fragment>
					)}
				</div>
				<div className="footer p-3">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</div>
			</div>
			</React.Fragment>
			
			
		);
	}
}

function mapStateToProps(state) {
	return {
		products: { ...state.productsReducer },
		invoice: { ...state.invoiceReducer },
		user: { ...state.userReducer },
	};
}

export default connect(mapStateToProps)(ShoppingCartTab);
