import React, { Component, Fragment } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { initializeUpdateUser } from '../store/user/user.action';
import _ from 'lodash';
import { getUserSubscription, getUserSubscriptionPage } from '../store/invoice/invoice.action';
import { TablePagination } from '@material-ui/core';
import ActionIcon from './assets/action-icon.png';
import { Dropdown, Alert, Spinner, Modal, Form, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { getAuthorizationToken, userKey  } from '../services/session.service';
import axios from 'axios';
import isValidDomain from 'is-valid-domain';
import UpgradePlanModal from './UpgradePlanModal';
import CreditCardInput from 'react-credit-card-input';

// <-- FOR STRIPE --> 
import {Elements, CardElement, useStripe, useElements, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import EditBillingInfo from './EditBillingInfo';
// import './CardSectionStyles.css'
// <-- FOR STRIPE --/> 

const apiBaseUrl = process.env.REACT_APP_API_URL;

// <-- FOR STRIPE --> 
const stripe_pk = process.env.REACT_APP_STRIPE_PK;
const stripePromise = loadStripe(stripe_pk);
const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true
  };

// <-- FOR STRIPE --/> 

export class MyProductsTab extends Component {
	state = {
		showInvoiceModal: false,
		isLoading: true,
		selectedInvoiceData: [],
		showChangeDomainModal: false,
		selectedDomain: [],
		selectedOrigDomain: [],
		newDomainName: "",
		selectedDomainId: [],
		isChangeDomainProcessing: false,
		addonFeatures: [],
		ccInfo: {
			cc: '',
			exp: '',
			cvc: '',
		}
	}
	componentDidMount() {
		console.log('this.props.user', this.props.user);
		this.props.dispatch(getUserSubscription()).then((response) => {
			this.getAllAddonFeatures();
			
		});
		
	}

	polishDomain = (domain) => {
		let polishedDomain = "";
		polishedDomain = domain.replace(/(^\w+:|^)\/\//, '');
		polishedDomain = polishedDomain.replace("www.", "");
		return polishedDomain;
	}

	handleChangeDomain = () => {
		if(isValidDomain(this.state.newDomainName)){
			this.setState({
				isChangeDomainProcessing: true
			}, () => {
				return axios
				.post(apiBaseUrl+'/api/user/domain/change/'+localStorage.getItem(userKey), 
				{
					domain_id: this.state.selectedDomainId,
					new_domain: this.polishDomain(this.state.newDomainName),
				},{
					headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
				})
				.then(response => {
					if(response.data.success){
						this.setState({
							isChangeDomainProcessing: false
						}, () => {
							alert(response.data.message);
							window.location.reload();
						});
					}
					else{
						this.setState({
							isChangeDomainProcessing: false
						}, () => {
							alert("Error occured during processing. Please try again later.");
						});
					}
				})
				.catch(response => {
					this.setState({
						isChangeDomainProcessing: false
					}, () => {
						alert("Server error! Please try again later.");
					});
				});
			})
		}
        else{
			alert('Please enter a valid domain!');
		}
	}

	handleChangePage = (event, page) => {
		this.setState({
			...this.state,
			isLoading: true
		}, () => {
			const pagination_data = this.props.invoice.invoice_pagination_data || {};
			if ((pagination_data.current_page || 1) - 1 > page) {
				if (pagination_data.prev_page_url) {
					this.props.dispatch(getUserSubscriptionPage(pagination_data.prev_page_url)).then((response) => {
						this.setState({
							...this.state,
							isLoading: false
						})
					});
				}
			} else if ((pagination_data.current_page || 1) - 1 < page) {
				if (pagination_data.next_page_url) {
					this.props.dispatch(getUserSubscriptionPage(pagination_data.next_page_url)).then((response) => {
						this.setState({
							...this.state,
							isLoading: false
						})
					});
				}
			}
		})
	};

	reformatDate = (date) => {
		const moment = require('moment');
		return("moment(date)",moment(date.split(" ")[0]).format('MMMM DD, YYYY')) 
	}

	showInvoices = (data) => {
		this.setState({
			showInvoiceModal: !this.state.showInvoiceModal,
			selectedInvoiceData: data,
		})
	}

	changeDomain = (domain_id, domain, orig_domain) => {
		this.setState({
			showChangeDomainModal: !this.state.showChangeDomainModal,
			selectedDomain: domain,
			selectedDomainId: domain_id,
			selectedOrigDomain: orig_domain,
		})
	}

	handleChangeDomainModalInputs = (e) => {
		e.preventDefault();
		this.setState({
			newDomainName: e.target.value
		});
	}

	getAllAddonFeatures = () => {
        return axios
		.get(apiBaseUrl+'/api/all-addons/'+localStorage.getItem(userKey), 
		{
			headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
		})
		.then(response => {
            this.setState({
                addonFeatures: response.data
            },()=>{
                this.setState({
					isLoading: false,
				})
            })
        })
        .catch(response => {
            alert(response)
        });
	}
	
	formatUnixDate = (date) => {
		let unix_timestamp = date;
		// Create a new JavaScript Date object based on the timestamp
		// multiplied by 1000 so that the argument is in milliseconds, not seconds.
		var date = new Date(unix_timestamp * 1000);
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var year = date.getFullYear();
		var formattedTime = month + "/" + day + "/" + year;

		return(formattedTime);
	}
	render() {
		const user = this.props.user;
		const pagination_data = this.props.invoice.invoice_pagination_data || {};
		const primaryAddress = user.primary_address;
		const secondaryAddress = user.secondary_address;
		const address = user.address;
		// const concat_address = address
		// 	? `
        // ${_.startCase}
        // ${_.startCase(address.address_line2 + ', ' || '')}
        // ${_.startCase(address.city + ', ' || '')}
        // ${_.startCase(address.state_province + ', ' || '')}
        // ${_.startCase(address.zipcode + ', ' || '')}
        // ${_.startCase(address.country || '')}
        // `
		// 	: '';
		const concat_address = `${( address.address_line1 ? `${address.address_line1}, ` : '')}${(address.address_line2 ? `${address.address_line2}, ` : '')}${(address.city ? `${address.city}, ` : '')}${( address.state_province ? `${address.state_province}, ` : '')}${( address.zipcode ? `${address.zipcode}, ` : '')}${( address.country ? `${address.country} ` : '')}`

		return (
			<Fragment>
				<Modal 
                    show={this.state.showInvoiceModal} 
                    onHide={() => {this.showInvoices([])}} 
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Invoices</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
						{
							this.state.showInvoiceModal ?
								<div className="shopping-cart-overflow" style={{height: '310px', overflow: 'scroll', overflowX: 'hidden'}}>
									<table className="shopping-cart-tbl">
										<thead>
											<tr className="heading-tbl">
												<th style={{minWidth: '150px'}}>Invoice #</th>
												<th style={{minWidth: '150px'}}>Date</th>
												<th style={{minWidth: '150px'}}>Currency</th>
												<th style={{minWidth: '150px'}}>Amount</th>
												
												{/* <th>Due Date</th> */}
												<th style={{minWidth: '150px'}}>Status</th>
												<th className="actions-lbl" style={{minWidth: '150px'}}>Actions</th>
											</tr>
										</thead>
										<tbody style={{fontSize: "14px",fontWeight: "600"}}>
											{this.state.selectedInvoiceData.map((data, index) => (
												<tr style={{border: '0',borderTop: "1px solid lightgray"}} key={index}>
													<td>{data.number}</td>
													<td>
														{this.formatUnixDate(data.created)}
													</td>
													<td>
														{data.currency.toUpperCase()}
													</td>
													<td>
														{parseFloat(Math.round((data.amount_due / 100) * 100) / 100).toFixed(2)}
													</td>
													
													{/* <td style={{width: '125px'}}>{this.reformatDate(data.created_at)}</td>
													{/* <td>{this.reformatDate(data.due_date)}</td> */}
													<td>{data.status.charAt(0).toUpperCase() + data.status.slice(1)}</td>
													<td className="paypal-row">
														{/* <Button
															className="receipt-btn"
															target="_blank"
															href={'/paid-invoice/' + data.invoice_number}
														>
															Receipt
														</Button> */}
														<Dropdown className="user-action-wrap">
															<Dropdown.Toggle id="dropdown-basic">
																<img className="action-btn" src={ActionIcon}></img>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item href={data.hosted_invoice_url} target="_blank">View</Dropdown.Item>
																<Dropdown.Item href={data.invoice_pdf} target="_blank">Download PDF</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td> 
												</tr>
											))}
										</tbody>
									</table>
								</div>
							:
							null
						} 
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=>{this.showInvoices([])}}>
                            Back
                        </Button>
                    </Modal.Footer>
                </Modal>

				<Modal 
					id="change-domain-modal"
                    show={this.state.showChangeDomainModal} 
                    onHide={() => {this.changeDomain("", "")}} 
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Change Domain</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
					<React.Fragment>
						<Form>
							<Form.Group as={Row} controlId="formPlaintextDomainName">
								<Form.Label column sm="4">
									Current Domain Name:
								</Form.Label>
								<Form.Label column sm="8">
									<a className="mr-3" href={this.state.selectedDomain} target="_blank">{this.state.selectedDomain}</a> 
									{
										this.state.selectedDomain != "https://"+this.state.selectedOrigDomain+".prosperna.ph" ? 
										<Button 
											className="process-btn" 
											onClick={
												()=>{
													this.setState({
														newDomainName: this.state.selectedOrigDomain+".prosperna.ph"
													}, () => {
														this.handleChangeDomain()
													});
												}
											}
										>
											Restore Prosperna Domain
										</Button>
										:
										null
									}
									
								</Form.Label>
							</Form.Group>
							<Form.Group as={Row} controlId="formPlaintextNewDomainName">
								<Form.Label column sm="4">
									New Domain Name:
								</Form.Label>
								<Col sm="8">
									<Form.Control name="newDomainName" type="text" placeholder="Ex. test.com" onChange={this.handleChangeDomainModalInputs.bind(this)}/>           
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="formPlaintextNewDomainName">
								<Form.Label column sm="4">
									Change Domain Instructions:
								</Form.Label>
								<Form.Label column sm="8">
									<a href="https://prospernasites.helpdocs.com/faq-s/changing-a-domain" target="_blank">https://prospernasites.helpdocs.com/faq-s/changing-a-domain</a>
								</Form.Label>
							</Form.Group>
						</Form>
					</React.Fragment>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={()=>{this.changeDomain("", "")}}>
                            Cancel
                        </Button>
						<Button onClick={()=>{this.handleChangeDomain()}} disabled={this.state.isChangeDomainProcessing}>
                            {this.state.isChangeDomainProcessing ? "Please wait..." : "Submit"}
                        </Button>
                    </Modal.Footer>
                </Modal>

				<div className="billing-info-container">
					<div className="billing-info-heading">
						<div className="billing-info-h-row-1">
							<h1>Billing Information</h1>
						</div>
						<div className="billing-info-h-row-2">
							{
								window.screen.width > 991 ?
									<EditBillingInfo />
								:
									null

							}
						</div>
					</div>

					<div className="billing-info-row">
						<div className="billing-info-row-1-container">
							<div className="billing-info-row-1">
								<p>
									Account Name<br />
									<span>{_.startCase(_.toLower(`${user.first_name} ${user.last_name}`))}</span>
								</p>
								<p>
									Company Name<br />
									<span>{_.startCase(_.toLower(user.company))}</span>
								</p>
							</div>
						</div>
						<div className="billing-info-row-2">
							<p>
								Address <br />
								<span>{concat_address}</span>
							</p>
							<p>
								Phone<br />
								<span>{user.phone}</span>
							</p>
							<p>
								Email Address<br />
								<span>{user.email_address}</span>
							</p>
						</div>
					</div>
					
					{
						window.screen.width <= 991 ?
							<div className="mt-3">
								<EditBillingInfo />
							</div>
						:
							null

					}
				</div>
				<div className="my-prod-inv-container">
					<h1>Subscriptions</h1>
					<div className="shopping-cart-overflow">
						{
							this.state.isLoading ?
								<React.Fragment>
									<center>
										<div className="mt-5 mb-3 mx-auto">
											<Spinner animation="grow" variant="danger" />
											<Spinner animation="grow" variant="warning" />
											<Spinner animation="grow" variant="success" />
										</div>
										{/* <div>
											<p>Please wait...</p>
										</div> */}
									</center>
								</React.Fragment>
							:
								<table className="shopping-cart-tbl">
									<thead>
										<tr className="heading-tbl">
											<th style={{minWidth: '145px'}}>Theme</th>
											<th style={{minWidth: '400px'}}>Description</th>
											<th style={{minWidth: '125px'}}>Issue Date</th>
											{/* <th>Due Date</th> */}
											<th style={{minWidth: '125px'}}>Plan Type</th>
											<th style={{minWidth: '100px'}}>Status</th>
											<th className="actions-lbl" style={{minWidth: '115px'}}>Actions</th>
										</tr>
									</thead>
									{
										<tbody style={{fontSize: "14px",fontWeight: "600"}}>
											{(pagination_data.data || []).map((data, index) => (
												console.log('array addons', JSON.parse(data.addon_feature_ids)),
												<tr key={index}>
													<td>{data.product.product_name}</td>
													<td>
														{data.product.description}
													</td>
													<td style={{width: '125px'}}>{this.reformatDate(data.created_at)}</td>
													{/* <td>{this.reformatDate(data.due_date)}</td> */}
													<td>{data.plan_type}</td>
													<td>{data.status != null ? data.status : "Payment Declined"}</td>
													<td className="paypal-row">
														<Dropdown className="user-action-wrap">
															<Dropdown.Toggle id="dropdown-basic">
																<img className="action-btn" src={ActionIcon}></img>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																{
																	data.domain != null ?
																		<React.Fragment>
																			{
																				data.product.product_name == "SME" || data.product.product_name == "Real Estate Pros" ?
																				<UpgradePlanModal 
																					data={{
																						subscription_id: data.stripe_subscription_id,
																						product_id: data.product.product_id,
																						customer_id: data.user.stripe_id,
																						addon_data: JSON.parse(data.addon_feature_ids),
																						currency: this.props.user.currency,
																						product_type_id: data.product.product_type_id,
																						plan_type: data.plan_type
																					}} 
																					addonOnly={true}
																				/>
																				:
																				data.plan_type != "Premium" ?
																					<React.Fragment>
																						<UpgradePlanModal 
																							data={{
																								subscription_id: data.stripe_subscription_id,
																								product_id: data.product.product_id,
																								customer_id: data.user.stripe_id,
																								addon_data: JSON.parse(data.addon_feature_ids),
																								currency: this.props.user.currency,
																								product_type_id: data.product.product_type_id,
																								plan_type: data.plan_type
																							}} 
																							addonOnly={false}
																						/>
																						<UpgradePlanModal 
																							data={{
																								subscription_id: data.stripe_subscription_id,
																								product_id: data.product.product_id,
																								customer_id: data.user.stripe_id,
																								addon_data: JSON.parse(data.addon_feature_ids),
																								currency: this.props.user.currency,
																								product_type_id: data.product.product_type_id,
																								plan_type: data.plan_type
																							}} 
																							addonOnly={true}
																						/>
																					</React.Fragment>
																				:
																				data.plan_type == "Premium" ? 
																					<React.Fragment>
																						<UpgradePlanModal 
																							data={{
																								subscription_id: data.stripe_subscription_id,
																								product_id: data.product.product_id,
																								customer_id: data.user.stripe_id,
																								addon_data: JSON.parse(data.addon_feature_ids),
																								currency: this.props.user.currency,
																								product_type_id: data.product.product_type_id,
																								plan_type: data.plan_type
																							}} 
																							addonOnly={true}
																						/>
																					</React.Fragment>
																				:
																					null
																			}
																			<Dropdown.Item href={"https://"+data.domain.current_domain_name} target="_blank">View Website</Dropdown.Item>
																			<Dropdown.Item href="/" onClick={(event) => { event.preventDefault(); this.changeDomain(data.domain.id, "https://"+data.domain.current_domain_name, data.domain.domain_name) }}>Change Domain</Dropdown.Item>
																			<Dropdown.Item href="/" onClick={(event) => { event.preventDefault(); this.showInvoices(data.invoices.data) }}>View Invoices</Dropdown.Item>
																		</React.Fragment>
																	:
																		<Dropdown.Item>No action available</Dropdown.Item>
																}
															</Dropdown.Menu>
														</Dropdown>
														{/* <Button
															className="receipt-btn"
															target="_blank"
															href={'/paid-invoice/' + data.invoice_number}
														>
															Receipt
														</Button> */}
													</td>
												</tr>
											))}
										</tbody>
									}
								</table>
						}
						{/* <TablePagination
							rowsPerPageOptions={[]}
							component="div"
							count={pagination_data.total || 0}
							rowsPerPage={pagination_data.per_page || 0}
							page={(pagination_data.current_page || 1) - 1}
							onChangePage={this.handleChangePage}
						/> */}
					</div>
					<TablePagination
						rowsPerPageOptions={[]}
						component="div"
						count={pagination_data.total || 0}
						rowsPerPage={pagination_data.per_page || 0}
						page={(pagination_data.current_page || 1) - 1}
						onChangePage={this.handleChangePage}
					/>
				</div>
				<footer class="footer mb-2">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</footer>
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	// console.log('state.userReducer', state.userReducer);
	return {
		user: { ...state.userReducer },
		invoice: { ...state.invoiceReducer }
	};
}

export default connect(mapStateToProps)(MyProductsTab);
