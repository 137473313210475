import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;
export default function signup(args = {}) {
    console.log('args', args);
    return axios.post(
        apiBaseUrl + '/api/user',
        { ...args },
        { headers: { 'Content-Type': 'application/json' } }
    );
}
