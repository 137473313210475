import * as types from './types';

const initialState = {
	first_name: '',
	email_address: '',
	last_name: '',
	phone: '',
	company: null,
	currency: null,
	user_id: 0,
	session_token: '',
	authenticated: false,
	primary_address: {
		city: null,
		country: null,
		state_province: null,
		street: null,
		zipcode: null
	},
	secondary_address: {
		city: null,
		country: null,
		state_province: null,
		street: null,
		zipcode: null
	}
};
function userReducer(state = initialState, action) {
	let new_state = state;
	switch (action.type) {
		case types.FETCH_OKAY:
			new_state = Object.assign({}, state, { ...state, ...action.payload });
			break;
		case types.FETCH_FAIL:
			new_state = Object.assign({}, state, {
				authKey: '',
				userId: ''
			});
			break;
		case types.UPADTE_USER:
			new_state = Object.assign({}, state, { ...state, ...action.payload });
			break;
		case types.AUTH_SUCCESS:
			new_state = Object.assign({}, state, { ...state, ...action.payload, authenticated: true });
			break;
		case types.AUTH_FAIL:
			new_state = Object.assign({}, state, {
				authenticated: false
			});
			break;
		default:
			break;
	}
	return new_state;
}
export default userReducer;
