import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Dropdown, Alert, Spinner, Modal, Form, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import axios from 'axios';
import { getAuthorizationToken, userKey  } from '../services/session.service';
import amplitude from 'amplitude-js';
import { connect } from 'react-redux';
const apiBaseUrl = process.env.REACT_APP_API_URL;

class UpgradePlanModal extends Component {
    state = { 
        showModal: false,
        premiumPrice: this.props.addonOnly ? 0 : 99,
        additionalFeature: [],
        additionalFeaturePrice: 0,
        totalAmount: this.props.addonOnly ? 0 : 99,
        isProcessing: false,
        addonFeatures: [],
        isFetching: true,
        boss_app_qty: 1,
    }

    componentDidMount() {
        console.log('this.props.user12312321', this.props.user);
        this.setState({
            totalAmount: parseFloat(this.state.premiumPrice + this.state.additionalFeaturePrice)
        },() => {
            console.clear();
            console.log('&& !this.props.addon_data.includes(item.id.toString()) ',this.props.data.addon_data)
        })
        if(this.state.isFetching){
            this.getAllAddonFeatures();
        }
        
    }

    componentWillUnmount(){
        this.setState({
            showModal: false,
            premiumPrice: this.props.addonOnly ? 0 : 99,
            additionalFeature: [],
            additionalFeaturePrice: 0,
            totalAmount: this.props.addonOnly ? 0 : 99,
            isProcessing: false,
            addonFeatures: [],
            isFetching: true,
            isNoAvailable: false
        })
    }

    getAllAddonFeatures = () => {
        return axios
		.get(apiBaseUrl+'/api/all-addons/'+localStorage.getItem(userKey), 
		{
			headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
		})
		.then(response => {
            const allAddons = response.data;
            let filteredAddons = [];

            if(this.props.addonOnly){
                filteredAddons = allAddons.filter(({product_type_ids, available_plan_types}) => JSON.parse(product_type_ids).includes(this.props.data.product_type_id) && JSON.parse(available_plan_types).includes(this.props.data.plan_type))
            }else{
                filteredAddons = allAddons.filter(({product_type_ids}) => JSON.parse(product_type_ids).includes(this.props.data.product_type_id))
            }

            this.setState({
                addonFeatures: filteredAddons,
            },()=>{
                if(this.props.data.addon_data.length == allAddons.filter(({is_one_payment, product_type_ids}) => is_one_payment == 0 && JSON.parse(product_type_ids).includes(this.props.data.product_type_id)).length){
                    // alert("You have already purchased all of the Add-on Features.")
                    this.setState({
                        isNoAvailable: true
                    });
                }
                else{
                    this.setState({
                        isFetching: false
                    });
                }
            })
        })
        .catch(response => {
            alert(response)
        });
    }

    executeUpdgrade = () => {
        this.setState({
            isProcessing: true
        }, () => {
            if(this.state.totalAmount > 0){
                if(this.props.addonOnly){
                    amplitude.getInstance().init(process.env.REACT_APP_APMPLITUDE_KEY);
                    amplitude.getInstance().logEvent("USER: PREMIUM ADDON CHECKOUT", {
                        email: this.props.user.email_address,
                        product_id: this.props.data.product_id,
                        product_name: this.props.data.product_name,
                        addon_ids: this.state.additionalFeature.concat(this.props.data.addon_data)
                    }, () => {
                        this.setState({
                            isProcessing: true
                        })
                    });
                }else{
                    amplitude.getInstance().init(process.env.REACT_APP_APMPLITUDE_KEY);
                    amplitude.getInstance().logEvent("USER: PREMIUM PLAN CHECKOUT", {
                        email: this.props.user.email_address,
                        product_id: this.props.data.product_id,
                        product_name: this.props.data.product_name,
                    }, () => {
                        this.setState({
                            isProcessing: true
                        })
                    });
                }
                let data = this.props.data;
                return axios
                .post(apiBaseUrl+'/api/subscription/plan/upgrade/'+localStorage.getItem(userKey), 
                {
                    subscription_id: data.subscription_id,
                    product_id: data.product_id,
                    customer_id: data.customer_id,
                    additional_feature: this.state.additionalFeature.concat(this.props.data.addon_data),
                    addon_only: this.props.addonOnly,
                    boss_app_qty: this.state.boss_app_qty
                },{
                    headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
                })
                .then(response => {
                    if(response.data.success){
                        if(this.props.addonOnly){
                            amplitude.getInstance().init(process.env.REACT_APP_APMPLITUDE_KEY);
                            amplitude.getInstance().logEvent("USER: PREMIUM ADDON PAYMENT SUCCESS", {
                                email: this.props.user.email_address,
                                product_id: this.props.data.product_id,
                                product_name: this.props.data.product_name,
                                addon_ids: this.state.additionalFeature.concat(this.props.data.addon_data)
                            }, () => {
                                alert('Premium Add-on Purchased Successfully!');
                                window.location.reload();
                            });
                        }else{
                            amplitude.getInstance().init(process.env.REACT_APP_APMPLITUDE_KEY);
                            amplitude.getInstance().logEvent("USER: PREMIUM PLAN PAYMENT SUCCESS", {
                                email: this.props.user.email_address,
                                product_id: this.props.data.product_id,
                                product_name: this.props.data.product_name,
                            }, () => {
                                alert('Premium Plan upgrade successful.');
                                window.location.reload();
                            });
                        }
                    }
                    else{
                        this.setState({
                            isProcessing: false
                        },()=>{
                            if(this.props.addonOnly){
                                alert('Premium Add-on Purchased Successfully!');
                            }else{
                                alert('Premium Plan upgrade failed.');
                            }
                        })
                    }
                })
                .catch(response => {
                    this.setState({
                        isProcessing: false
                    },()=>{
                        alert('Server Error! ' + response)
                    })
                });
            }
            else{
                alert('Please select an add-on that you want to purchase!');
            }
        })
    }

    handleModalBehavior = () => {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    addonFeatureOnChange = (val_id, val_id2, val_value) => {
        const {additionalFeature, additionalFeaturePrice, totalAmount} = this.state;
        let isAmountToBeAdd = false;

        const id = val_id != null ? val_id.toString() : null, 
        id2 = val_id2 != null ? val_id2.toString() : null, 
        value = val_value != null ? val_value.toString() : null;

        if(additionalFeature.includes(id2)){
            const index = additionalFeature.indexOf(id2);
            if (index > -1) {
                additionalFeature.splice(index, 1);
            }
            additionalFeature.push(id)
            isAmountToBeAdd = true;
        }
        // else if(e.target.id == '5' && additionalFeature.includes('4')){
        //     const index = additionalFeature.indexOf('4');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '12' && additionalFeature.includes('13')){
        //     const index = additionalFeature.indexOf('13');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '13' && additionalFeature.includes('12')){
        //     const index = additionalFeature.indexOf('12');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '1' && additionalFeature.includes('17')){
        //     const index = additionalFeature.indexOf('17');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '17' && additionalFeature.includes('1')){
        //     const index = additionalFeature.indexOf('1');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '10' && additionalFeature.includes('18')){
        //     const index = additionalFeature.indexOf('18');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '18' && additionalFeature.includes('10')){
        //     const index = additionalFeature.indexOf('10');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '6' && additionalFeature.includes('19')){
        //     const index = additionalFeature.indexOf('19');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '19' && additionalFeature.includes('6')){
        //     const index = additionalFeature.indexOf('6');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '14' && additionalFeature.includes('20')){
        //     const index = additionalFeature.indexOf('20');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '20' && additionalFeature.includes('14')){
        //     const index = additionalFeature.indexOf('14');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '21' && additionalFeature.includes('23')){
        //     const index = additionalFeature.indexOf('23');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '23' && additionalFeature.includes('21')){
        //     const index = additionalFeature.indexOf('21');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '22' && additionalFeature.includes('24')){
        //     const index = additionalFeature.indexOf('24');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '24' && additionalFeature.includes('22')){
        //     const index = additionalFeature.indexOf('22');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '25' && additionalFeature.includes('27')){
        //     const index = additionalFeature.indexOf('27');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '27' && additionalFeature.includes('25')){
        //     const index = additionalFeature.indexOf('25');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '26' && additionalFeature.includes('28')){
        //     const index = additionalFeature.indexOf('28');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // else if(e.target.id == '28' && additionalFeature.includes('26')){
        //     const index = additionalFeature.indexOf('26');
        //     if (index > -1) {
        //         additionalFeature.splice(index, 1);
        //     }
        //     additionalFeature.push(e.target.id)
        //     isAmountToBeAdd = true;
        // }
        // this.props.data.addon_data.includes('6') && item.id == '19' || this.props.data.addon_data.includes('14') && item.id == '20' ||
        // this.props.data.addon_data.includes('21') && item.id == '22' || this.props.data.addon_data.includes('23') && item.id == '24' ?
        else if(additionalFeature.includes(id)){
            const index = additionalFeature.indexOf(id);
            if (index > -1) {
                additionalFeature.splice(index, 1);
            }
            isAmountToBeAdd = false;
        }else{
            additionalFeature.push(id)
            isAmountToBeAdd = true;
        }
        this.setState({
            additionalFeature: additionalFeature,
            additionalFeaturePrice: parseFloat(value),
        }, () => {
            console.log('additionalFeature: additionalFeature', this.state.additionalFeature)
            const {additionalFeature, additionalFeaturePrice, totalAmount, addonFeatures} = this.state;
            // const tempTotalAmount = isAmountToBeAdd ? totalAmount + parseFloat(this.state.premiumPrice + this.state.additionalFeaturePrice) : totalAmount + parseFloat(this.state.premiumPrice - this.state.additionalFeaturePrice);
            // const finalTotalAmount = additionalFeature.length == 0 ? 0 : tempTotalAmount;

            let finalTotalAmount = 0;
            for(let count1 = 0; count1 < additionalFeature.length; count1++){
                for(let count = 0; count < addonFeatures.length; count++){
                    if(addonFeatures[count].id == additionalFeature[count1]){
                        if(addonFeatures[count].name == "BOSS App"){
                            finalTotalAmount = finalTotalAmount + parseFloat(addonFeatures[count].price * this.state.boss_app_qty) / 2;
                        }else{
                            finalTotalAmount = finalTotalAmount + parseFloat(addonFeatures[count].price) / 2;
                        }
                 
                    }
                }
            }
            this.setState({
                totalAmount: finalTotalAmount + parseFloat(this.state.premiumPrice + finalTotalAmount)
            })
        })
    }

    formatAmount = (amount) => {
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        return priceSplitter(parseFloat(Math.round(amount * 100) / 100).toFixed(2))
    }

    bossAppQtyOnChange = (e) => {
        this.setState({
            ...this.state,
            boss_app_qty: e.target.value == '' ? 1 : e.target.value
        }, () => {
            console.log('additionalFeature: additionalFeature', this.state.additionalFeature)
            const {additionalFeature, additionalFeaturePrice, totalAmount, addonFeatures} = this.state;
            // const tempTotalAmount = isAmountToBeAdd ? totalAmount + parseFloat(this.state.premiumPrice + this.state.additionalFeaturePrice) : totalAmount + parseFloat(this.state.premiumPrice - this.state.additionalFeaturePrice);
            // const finalTotalAmount = additionalFeature.length == 0 ? 0 : tempTotalAmount;

            let finalTotalAmount = 0;
            for(let count1 = 0; count1 < additionalFeature.length; count1++){
                for(let count = 0; count < addonFeatures.length; count++){
                    if(addonFeatures[count].id == additionalFeature[count1]){
                        if(addonFeatures[count].name == "BOSS App"){
                            finalTotalAmount = finalTotalAmount + parseFloat(addonFeatures[count].price * this.state.boss_app_qty) / 2;
                        }else{
                            finalTotalAmount = finalTotalAmount + parseFloat(addonFeatures[count].price) / 2;
                        }
                 
                    }
                }
            }
            this.setState({
                totalAmount: finalTotalAmount + parseFloat(this.state.premiumPrice + finalTotalAmount)
            })
        })
    }

    render() { 
        return ( 
            <React.Fragment>
                <Dropdown.Item onClick={() => {this.handleModalBehavior()}}>
                    {
                        this.props.addonOnly ?
                            'Purchase Add-on Features'
                            
                        :
                            'Updgrade to Premium'
                    }
                </Dropdown.Item>
                <Modal 
					id="upgrade-plan-modal"
                    show={this.state.showModal} 
                    onHide={() => {this.handleModalBehavior()}} 
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {
                                this.props.addonOnly ?
                                    'Purchase Add-on Features'
                                    
                                :
                                    'Premium Plan Upgrade'
                            }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
                        <React.Fragment>
                            {
                                this.state.isFetching ?
                                null
                                :
                                this.props.addonOnly ?
                                    // <Form.Group as={Row} controlId="formPlaintextPlanDetails">
                                    //     <Form.Label column sm="3">
                                    //         Premium Plan Price:
                                    //     </Form.Label>
                                    //     <Form.Label column sm="9">
                                    //         $99.00 / month
                                    //     </Form.Label>
                                    // </Form.Group>\
                                    null
                                :
                                <Form.Group as={Row} controlId="formPlaintextPlanDetails">
                                    <Form.Label column sm="3">
                                        Premium Plan Upgrade:
                                    </Form.Label>
                                    <Form.Label column sm="9">
                                        $99.00 / month
                                    </Form.Label>
                                </Form.Group>
                            }
                            {
                                this.state.isNoAvailable ?
                                <center>
                                    You have already purchased all of the Add-on Features.
                                </center>
                                :
                                this.state.isFetching ?
                                <center>
                                    <Spinner animation="grow" variant="danger" size="sm" />
                                    <Spinner animation="grow" variant="warning" size="sm" />
                                    <Spinner animation="grow" variant="success" size="sm" />
                                </center>
                                :
                                <React.Fragment>
                                    <Form.Group as={Row} controlId="formPlaintextAdditionalFeatures">
                                        <Form.Label column sm="3">
                                        Add-on Features:
                                        </Form.Label>
                                        <Form.Label column sm="9">
                                            {
                                                this.state.addonFeatures.map((item, index) => (
                                                    // this.props.data.addon_data.includes(item.one_time_id) && item.id == '4' || this.props.data.addon_data.includes('13') && item.id == '12'  || this.props.data.addon_data.includes('17') && item.id == '1' || 
                                                    // this.props.data.addon_data.includes('18') && item.id == '10' || this.props.data.addon_data.includes('19') && item.id == '6' || this.props.data.addon_data.includes('20') && item.id == '14' ||
                                                    // this.props.data.addon_data.includes('23') && item.id == '21' || this.props.data.addon_data.includes('24') && item.id == '22' || this.props.data.addon_data.includes('25') && item.id == '27' || this.props.data.addon_data.includes('26') && item.id == '28'
                                                    this.props.data.addon_data.includes(item.one_time_id == null ? null : item.one_time_id.toString()) ?
                                                    null
                                                    :
                                                    item.is_one_payment == 0 && !this.props.data.addon_data.includes(item.id.toString()) ?
                                                    <React.Fragment>
                                                        {
                                                            item.name == 'BOSS App' ? 
                                                                <React.Fragment>
                                                                    <Form.Check 
                                                                        inline 
                                                                        id={item.id}
                                                                        id2={item.one_time_id}
                                                                        name={item.description}
                                                                        value={item.price}
                                                                        label={item.description}
                                                                        type={"checkbox"}
                                                                        onChange={() => this.addonFeatureOnChange(item.id, item.one_time_id, item.price)}
                                                                        checked={this.state.additionalFeature.includes(item.id.toString())}
                                                                    />
                                                                    {
                                                                        this.state.additionalFeature.includes(item.id.toString()) ? 
                                                                        <Form.Control inline name="qty" type="number" placeholder="Qty (Default: 1)" min="1" onChange={this.bossAppQtyOnChange.bind(this)} defaultValue={this.state.boss_app_qty}/>
                                                                        :
                                                                        <br/>
                                                                    }
                                                                    
                                                                </React.Fragment>
                                                            :
                                                                <React.Fragment>
                                                                    <Form.Check 
                                                                        inline 
                                                                        id={item.id}
                                                                        id2={item.one_time_id}
                                                                        name={item.description}
                                                                        value={item.price}
                                                                        label={item.description}
                                                                        type={"checkbox"}
                                                                        onChange={() => this.addonFeatureOnChange(item.id, item.one_time_id, item.price)}
                                                                        checked={this.state.additionalFeature.includes(item.id.toString())}
                                                                    />
                                                                    <br/>
                                                                </React.Fragment>

                                                        }
                                                    </React.Fragment>
                                                    : 
                                                    null
                                                ))
                                            }
                                            <hr/>
                                            {
                                                this.state.addonFeatures.map((item, index) => (
                                                    // this.props.data.addon_data.includes('4') && item.id == '5' || this.props.data.addon_data.includes('12') && item.id == '13' || this.props.data.addon_data.includes('1') && item.id == '17' || 
                                                    // this.props.data.addon_data.includes('10') && item.id == '18' || this.props.data.addon_data.includes('6') && item.id == '19' || this.props.data.addon_data.includes('14') && item.id == '20' ||
                                                    // this.props.data.addon_data.includes('21') && item.id == '23' || this.props.data.addon_data.includes('22') && item.id == '24' || this.props.data.addon_data.includes('27') && item.id == '25' || this.props.data.addon_data.includes('28') && item.id == '26' ?
                                                    this.props.data.addon_data.includes(item.monthly_sub_id == null ? null : item.monthly_sub_id.toString()) ?
                                                    null
                                                    :
                                                    item.is_one_payment == 1 && !this.props.data.addon_data.includes(item.id.toString()) ?
                                                    <React.Fragment>
                                                        {
                                                            item.name == 'BOSS App' ? 
                                                            <React.Fragment>
                                                                <Form.Check 
                                                                    inline 
                                                                    id={item.id}
                                                                    id2={item.monthly_sub_id}
                                                                    name={item.description}
                                                                    value={item.price}
                                                                    label={item.description}
                                                                    type={"checkbox"}
                                                                    onChange={() => this.addonFeatureOnChange(item.id, item.monthly_sub_id, item.price)}
                                                                    checked={this.state.additionalFeature.includes(item.id.toString())}
                                                                />
                                                                {
                                                                    this.state.additionalFeature.includes(item.id.toString()) ? 
                                                                    <Form.Control inline name="qty" type="number" placeholder="Qty (Default: 1)" min="1" onChange={this.bossAppQtyOnChange.bind(this)} defaultValue={this.state.boss_app_qty}/>
                                                                    :
                                                                    <br/>
                                                                }
                                                                
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <Form.Check 
                                                                    inline 
                                                                    id={item.id}
                                                                    id2={item.monthly_sub_id}
                                                                    name={item.description}
                                                                    value={item.price}
                                                                    label={item.description}
                                                                    type={"checkbox"}
                                                                    onChange={() => this.addonFeatureOnChange(item.id, item.monthly_sub_id, item.price)}
                                                                    checked={this.state.additionalFeature.includes(item.id.toString())}
                                                                />
                                                                <br/>
                                                                </React.Fragment>
                                                        }
                                                        
                                                      
                                                    </React.Fragment>
                                                    : 
                                                    null
                                                ))
                                            }
                                            {/* <br/>
                                            <Form.Check 
                                                inline 
                                                name="none"
                                                value="0"
                                                label={"None"} 
                                                type={"checkbox"}
                                                onChange={this.addonFeatureOnChange.bind(this)}
                                                checked={
                                                    this.state.additionalFeature.length == 0 ?
                                                        true
                                                    :
                                                        false
                                                }
                                            /> */}
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextAmount">
                                        <Form.Label column sm="3">
                                            Total:
                                        </Form.Label>
                                        <Form.Label column sm="9">
                                            {(this.props.user.currency == 1 ? "$" : "₱")+(this.formatAmount(this.state.totalAmount))+" / month"}
                                        </Form.Label>
                                    </Form.Group>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            this.state.isNoAvailable ? 
                                null
                            :
                            <React.Fragment>
                                <Button onClick={()=>{this.handleModalBehavior()}}>
                                    Cancel
                                </Button>
                                <Button 
                                    style={{

                                        backgroundColor: "#2ca94f",
                                        color: "#fff",
                                        padding: "10px 20px",
                                        border: "1px solid #2ca94f",
                                        minWidth: "130px",
                                        borderRadius: "5px",
                                        
                                    }} 
                                    onClick={()=>{this.executeUpdgrade()}} 
                                    disabled={this.state.isProcessing}
                                >
                                    {this.state.isProcessing ? "Please wait..." : "Submit"}
                                </Button>
                            </React.Fragment>
                        }
                        
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}
 
function mapStateToProps(state) {
	// console.log('state.userReducer', state.userReducer);
	return {
		user: { ...state.userReducer },
		invoice: { ...state.invoiceReducer }
	};
}

export default connect(mapStateToProps)(UpgradePlanModal);