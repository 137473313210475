import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';

import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;
class InAppBanner extends Component {
	constructor() {
		super();
		this.state = {
			daysRemaining: '',
			show: true
		};
	}

	componentDidMount() {
		this.getExpCount();
	}

	componentDidUpdate() {}

	getExpCount = () => {
		return axios
			.get(apiBaseUrl + '/get-user-website-expiration?id=' + localStorage.getItem('userKey'), {
				headers: { 'Content-Type': 'application/json' }
			})
			.then((response) => {
				if(response.data === null){
					this.setState({ show: false });
				}
				else{
					this.setState({ daysRemaining: response.data, show: true });
				}
			});
	};
	
	render() {
		return this.state.show && this.state.daysRemaining > 0 ? (
			<Alert variant="warning" onClose={() => this.setState({ show: false })} dismissible>
				<p className="m-0">{'Your subscription ends in ' + this.state.daysRemaining + ' days.'}</p>
				
			</Alert>
		) : this.state.show && this.state.daysRemaining < 0 ? 
		(
			<Alert variant="warning" onClose={() => this.setState({ show: false })} dismissible>
				<p className="m-0">{'Your website has been suspended!'+this.state.daysRemaining}</p>
			</Alert>
		) : this.state.show && this.state.daysRemaining === 0 ? 
		(
			<Alert variant="warning" onClose={() => this.setState({ show: false })} dismissible>
				<p className="m-0">{'Your subscription is due today!'}</p>
			</Alert>
		) :
		(
			''
		);
	}
}

export default InAppBanner;
