import * as types from './types';
import { getProductsLising, getProductsLisingPage } from '../../services/products.service';
import { userKey } from '../../services/session.service';
export function fetchProductListingSuccess(payload) {
    return { type: types.FETCH_PRODUCT_LISTING_SUCCESS, payload };
}
export function fetchProductListingFail(payload) {
    return { type: types.FETCH_PRODUCT_LISTING_FAIL, payload };
}

export function fetchProductsListings(currency_id) {
    const userId = localStorage.getItem(userKey) || '';
    return (dispatch) => {
        return getProductsLising(userId, currency_id)
            .then((response) => {
                console.log('getProductsLising response.data ', response.data);
                dispatch(fetchProductListingSuccess({ data: response.data }));
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchProductListingFail({ message: error }));
                return Promise.reject(error);
            });
    };
}

export function fetchProductsListingsPage(url) {
    const userId = localStorage.getItem(userKey) || '';
    return (dispatch) => {
        return getProductsLisingPage(url)
            .then((response) => {
                console.log('getProductsLisingPage response.data ', response.data);
                dispatch(fetchProductListingSuccess({ data: response.data }));
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                dispatch(fetchProductListingFail({ message: error }));
                return Promise.reject(error);
            });
    };
}