import axios from 'axios';
import { sessionKey, userKey } from './session.service';
const apiBaseUrl = process.env.REACT_APP_API_URL;
export default function login(params = {}) {
    return axios.get(apiBaseUrl + '/api/user', { params: { ...params } }).then((data) => {
        console.log('data.data.', data.data);
        localStorage.setItem(sessionKey, data.data.session_token || '');
        localStorage.setItem(userKey, data.data.user_id || '');
        localStorage.setItem("userKey", data.data.user_id || '');
        return data;
    });
}
