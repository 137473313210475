import React, { Component } from 'react';
import Logo from '../assets/prospernav2-log.png';
import ActionIcon from '../assets/action-icon.png';
import ActionCancelIcon from '../assets/action-cancel-icon.png';
import '../Styles.css';
import LogoutButton from '@material-ui/core/Button';
import { Tabs, Tab, Dropdown, Button } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import axios from 'axios';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { CSVExport } from 'react-bootstrap-table2-toolkit';
import PromoCodeModal from './PromoCodeModal';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';

import {Alert, Spinner, Modal, Form, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';

const { SearchBar } = Search;

const { ExportCSVButton } = CSVExport;
const apiBaseUrl = process.env.REACT_APP_API_URL;



class AdminDashboard extends Component {
    state = { 
        accounts: [],
        activeAccounts: [],
        allAccounts: [],
        isLoading: true,
        promoCodes: [],
        isFecthingSubscriptions: true,
        isFecthingPromoCodes: true,
        showSubDetailsModal: false,
        selectedSubData: []
    }

    componentDidMount(){
        this.getAccounts();
        this.getPromoCodes();
    }

    getPromoCodes = () => {
        this.setState({
            isFecthingPromoCodes: true
        }, () => {
            return axios
            .get(apiBaseUrl+'/get-all-promo-codes', 
            {
                headers: {'Content-Type':'application/json'}
            })
            .then(response => {
                this.buildPromoCodes(response.data.data);
            })
            .catch(error => {
                this.setState({
                    isFecthingPromoCodes: false
                })
                alert(error);
            });
        })
		
    }

    getAccounts = () => {
		return axios
		.get(apiBaseUrl+'/api/all-subscriptions', 
		{
			headers: {'Content-Type':'application/json'}
		})
		.then(response => {
            this.buildActiveAccounts(response.data);
			this.setState({
                isFecthingSubscriptions: false
			})
        })
        .catch(error => {
            this.setState({
                isFecthingSubscriptions: false
			})
            alert(error);
        });
    }

    cancelUserPlan = (e) => {
        e.preventDefault();
        return axios
        .post(apiBaseUrl+'/api/user/cancel-user-plan', 
        {
            id: e.target.id,
            value: "",
        },
		{
			headers: {'Content-Type':'application/json'}
		})
		.then(response => {
            window.location.reload();
		});
    }

    deletePromoCode = (e) => {
        e.preventDefault();
        return axios
        .post(apiBaseUrl+'/api/delete-promo-code', 
        {
            id: e.target.id,
        },
		{
			headers: {'Content-Type':'application/json'}
		})
		.then(response => {
            this.getPromoCodes();
		});
    }

    buildPromoCodes = (data) => {
        for(let count = 0; count < data.length; count++){
            data[count].discount = data[count].discount;
            data[count].email_address = data[count].email_address === null ? 'Everyone' : data[count].email_address;
            data[count].action = 
            <Dropdown className="admin-action-wrap">
                <Dropdown.Toggle id="dropdown-basic">
                    <img className="action-btn" src={ActionIcon}></img>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item href="/" id={data[count].id} onClick={this.deletePromoCode.bind(this)}><span><img className="cancel-btn-icon" src={ActionCancelIcon} /></span>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            if(data[count].is_used === 0){
                data[count].is_used = "Active"; 
            }
            else{
                data[count].is_used = "Inactive"; 
            }
            
        }
        this.setState({
            promoCodes: data,
            isFecthingPromoCodes: false
        });
    } 

    showSubDetails = (data) => {
		this.setState({
			showSubDetailsModal: !this.state.showSubDetailsModal,
			selectedSubData: data,
		})
    }
    
    buildActiveAccounts = (data) => {
        let activeAccounts = [];
        let inactiveAccounts = [];
        let allAccounts = [];
        for(let count = 0; count < data.length; count++){
            try{
                data[count].domain = <a href={"https://"+data[count].domain.current_domain_name} target="_blank">{data[count].domain.current_domain_name}</a>
            }
            catch{
                data[count].domain = null
            }
            data[count].status = data[count].status != null ? data[count].status : "Payment Declined"
            data[count].product = data[count].product.product_name

            const subData = {
                invoice_data: data[count].invoices.data,
                promo_code: data[count].promo_code === null ? null : data[count].promo_code.promo_code,
                seo_type: data[count].seo_type === null ? null : data[count].seo_type.type.charAt(0).toUpperCase() + data[count].seo_type.type.slice(1),
                setup_type: data[count].setup_type === null ? null : data[count].setup_type.type.charAt(0).toUpperCase() + data[count].setup_type.type.slice(1), 
                plan_type: data[count].plan_type
            };

            data[count].action = 
                <Dropdown className="admin-action-wrap">
                    <Dropdown.Toggle id="dropdown-basic">
                        <img className="action-btn" src={ActionIcon}></img>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href="/" id={data[count].user_id} onClick={(event) => { event.preventDefault(); this.showSubDetails(subData)}}>View details</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            allAccounts.push(data[count]);
            
            Object.keys(data[count]).forEach(function(key) {
                if(data[count][key] === null) {
                    data[count][key] = '-';
                }
            })
        }
        this.setState({
            activeAccounts: activeAccounts,
            inactiveAccounts: inactiveAccounts,
            allAccounts: allAccounts
        })
    }

    removeTableBorder = () => {
        document.getElementById("active-accounts-table").className = "table";
        document.getElementById("inactive-accounts-table").className = "table";
        document.getElementById("all-accounts-table").className = "table";
    }

    accountsColumns = () => {
        return [{
            dataField: 'product',
            text: 'Theme'
            }, {
            dataField: 'domain',
            text: 'Website URL'
            }, {
            dataField: 'user.email_address',
            text: 'Email'
            }, {
            dataField: 'subscription_status',
            text: 'Status'
            }, {
            dataField: 'user.industry',
            text: 'Industry'
            }, {
            dataField: 'created_at',
            text: 'Payment Date'
            }, {
            dataField: 'action',
            text: 'Actions'
        }]; 
    }

    promoCodesColumns = () => {
        return [{
            dataField: 'promo_code',
            text: 'Promo Code'
            }, {
            dataField: 'discount',
            text: 'Discount'
            }, {
            dataField: 'email_address',
            text: 'Created for'
            }, {
            dataField: 'is_used',
            text: 'Status'
            }, {
            dataField: 'expiration_date',
            text: 'Expiration Date'
            }, {
            dataField: 'created_at',
            text: 'Created Date'
            }, {
            dataField: 'action',
            text: 'Actions'
        }];
    }
    formatUnixDate = (date) => {
		let unix_timestamp = date;
		// Create a new JavaScript Date object based on the timestamp
		// multiplied by 1000 so that the argument is in milliseconds, not seconds.
		var date = new Date(unix_timestamp * 1000);
		var month = date.getMonth() + 1;
		var day = date.getDate();
		var year = date.getFullYear();
		var formattedTime = month + "/" + day + "/" + year;

		return(formattedTime);
	}
    render() { 
        const activeAccounts = this.state.activeAccounts;
        const inactiveAccounts = this.state.inactiveAccounts;
        const allAccounts = this.state.allAccounts;
        const promoCodes = this.state.promoCodes;
        const columns = this.accountsColumns();
        const promoCodesTblCol = this.promoCodesColumns();
        const classes = makeStyles((theme) => ({
			root: {
			  flexGrow: 1,
			},
			menuButton: {
			  marginRight: theme.spacing(2),
			},
			title: {
			  flexGrow: 1,
			},
        }));
        
        const {invoice_data, promo_code, seo_type, setup_type, plan_type} = this.state.selectedSubData;
        return ( 
            // this.state.isLoading ? 
			// <p>Loading...</p>
			// :
            <React.Fragment>
                <Modal 
                    show={this.state.showSubDetailsModal} 
                    onHide={() => {this.showSubDetails([])}} 
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="">
						{
                            this.state.showSubDetailsModal ?
                            <React.Fragment>
                                <h4>Subscription Details</h4>
                                <hr/>
                                <Form>
                                    <Form.Group as={Row} controlId="formPlaintextPromoCode">
                                        <Form.Label column sm="3">
                                            Promo code:
                                        </Form.Label>
                                        <Form.Label column sm="9">
                                            {promo_code}
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextPlanType">
                                        <Form.Label column sm="3">
                                            Plan Type:
                                        </Form.Label>
                                        <Form.Label column sm="9">
                                            {plan_type}
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextSetupType">
                                        <Form.Label column sm="3">
                                            Setup Type:
                                        </Form.Label>
                                        <Form.Label column sm="9">
                                            {setup_type}
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextSEOType">
                                        <Form.Label column sm="3">
                                            SEO Type:
                                        </Form.Label>
                                        <Form.Label column sm="9">
                                            {seo_type}
                                        </Form.Label>
                                    </Form.Group>
                                </Form>
                                <h4>Subscription Invoices</h4>
                                <hr/>
								<div className="shopping-cart-overflow">
									<table className="shopping-cart-tbl">
										<thead>
											<tr className="heading-tbl">
												<th style={{minWidth: '150px'}}>Invoice #</th>
												<th style={{minWidth: '150px'}}>Date</th>
												<th style={{minWidth: '150px'}}>Currency</th>
												<th style={{minWidth: '150px'}}>Amount</th>
												<th style={{minWidth: '150px'}}>Status</th>
												<th className="actions-lbl" style={{minWidth: '150px'}}>Actions</th>
											</tr>
										</thead>
										<tbody style={{fontSize: "14px",fontWeight: "600"}}>
											{invoice_data.map((data, index) => (
												<tr style={{border: '0',borderTop: "1px solid lightgray"}} key={index}>
													<td>{data.number}</td>
                                                    <td>
														{this.formatUnixDate(data.created)}
													</td>
													<td>
														{data.currency.toUpperCase()}
													</td>
													<td>
														{parseFloat(Math.round((data.amount_due / 100) * 100) / 100).toFixed(2)}
													</td>
													<td>{data.status.charAt(0).toUpperCase() + data.status.slice(1)}</td>
													<td className="paypal-row">
														<Dropdown className="user-action-wrap">
															<Dropdown.Toggle id="dropdown-basic">
																<img className="action-btn" src={ActionIcon}></img>
															</Dropdown.Toggle>
															<Dropdown.Menu>
																<Dropdown.Item href={data.hosted_invoice_url} target="_blank">View</Dropdown.Item>
																<Dropdown.Item href={data.invoice_pdf} target="_blank">Download PDF</Dropdown.Item>
															</Dropdown.Menu>
														</Dropdown>
													</td> 
												</tr>
											))}
										</tbody>
									</table>
								</div>
                            </React.Fragment>
							:
							null
						} 
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
                <div className="admin-main-container">
                    {
                        window.screen.width < 992 ?
                        <React.Fragment>
                            <AppBar position="fixed">
                                <Toolbar>
                                        <img alt="Prosperna" className="logo" src={Logo} width="140"/>
                                        <IconButton 
                                            edge="start" 
                                            className={classes.menuButton} 
                                            color="inherit" aria-label="menu" 
                                            onClick={() => {
                                                this.setState({
                                                    ...this.state,
                                                    openSideNav:true
                                                })
                                            }}
                                        >
                                            <MenuIcon />
                                        </IconButton>
                                </Toolbar>
                            </AppBar>
                            <SwipeableDrawer
                                anchor={'right'}
                                open={this.state.openSideNav}
                                onClose={() => {
                                    this.setState({
                                        ...this.state,
                                        openSideNav:false
                                    })
                                }}
                                onOpen={() => {
                                    this.setState({
                                        ...this.state,
                                        openSideNav:true
                                    })
                                }}
                            >
                                <List className="avatar-wrap">
                                    <Avatar>{this.props.user.first_name.charAt(0)+this.props.user.last_name.charAt(0)}</Avatar>
                                    <h5 className="text-center m-0 mt-1">{this.props.user.first_name+" "+this.props.user.last_name}</h5>
                                    <p className="text-center m-0">{this.props.user.email_address}</p>
                                    <p className="text-center m-0">{this.props.user.phone}</p>
                                </List>
                                <Divider />
                                <List>
                                    <ListItem button onClick={() => {window.location.href='/login'}}>
                                        <ListItemText className="font-bold" primary={'Logout'} />
                                    </ListItem>
                                </List>
                            </SwipeableDrawer>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <div className="main-header">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="header-logo">
                                                <img alt="Prosperna" className="logo" src={Logo} />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="header-nav">
                                                
                                            <LogoutButton className="logout-btn" href="/mp-admin">
                                                Logout
                                            </LogoutButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </React.Fragment>
                    }
                    <section id="admin__user_accounts">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <Tabs defaultActiveKey="all-accounts" id="uncontrolled-tab-example">
                                        <Tab eventKey="all-accounts" title="Customer Subscriptions">
                                            <div className="accounts-table-container">   
                                                {
                                                    this.state.isFecthingSubscriptions ?
                                                    <React.Fragment>
                                                        <div className="mt-5 mb-3">
                                                            <Spinner animation="grow" variant="danger" />
                                                            <Spinner animation="grow" variant="warning" />
                                                            <Spinner animation="grow" variant="success" />
                                                        </div>
                                                        <div>
                                                            <p>Getting all subscriptions, please wait.</p>
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        data={ allAccounts } 
                                                        columns={ columns } 
                                                        search
                                                        exportCSV
                                                        
                                                        >
                                                        {
                                                            props => (
                                                            <div>
                                                                <ExportCSVButton className="csv-export func-btn" { ...props.csvProps }>Download CSV</ExportCSVButton>
                                                                <SearchBar { ...props.searchProps } />
                                                                <BootstrapTable
                                                                bordered={ false }
                                                                { ...props.baseProps }
                                                                />
                                                            </div>
                                                            )
                                                        }
                                                    </ToolkitProvider>
                                                }
                                            </div>
                                        </Tab>
                                        <Tab eventKey="promo-codes" title="Promo Codes">
                                            <div className="accounts-table-container">  
                                                {
                                                    this.state.isFecthingPromoCodes ?
                                                    <React.Fragment>
                                                        <div className="mt-5 mb-3">
                                                            <Spinner animation="grow" variant="danger" />
                                                            <Spinner animation="grow" variant="warning" />
                                                            <Spinner animation="grow" variant="success" />
                                                        </div>
                                                        <div>
                                                            <p>Getting all promo codes, please wait.</p>
                                                        </div>
                                                    </React.Fragment>
                                                    :
                                                    <ToolkitProvider
                                                        keyField="id"
                                                        data={ promoCodes } 
                                                        columns={ promoCodesTblCol } 
                                                        search
                                                        exportCSV
                                                        >
                                                        {
                                                            props => (
                                                            <div>
                                                                <ExportCSVButton className="csv-export func-btn" { ...props.csvProps }>Download CSV</ExportCSVButton>
                                                                <PromoCodeModal getPromoCodes={this.getPromoCodes} />
                                                                <SearchBar { ...props.searchProps } />
                                                                <BootstrapTable
                                                                bordered={ false }
                                                                { ...props.baseProps }
                                                                pagination={ paginationFactory()}
                                                                />
                                                            </div>
                                                            )
                                                        }
                                                    </ToolkitProvider>
                                                }
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className="footer m-5">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</div>
            </React.Fragment>
            
        );
    }
}
 
function mapStateToProps(state) {
	return {
		products: { ...state.productsReducer },
		invoice: { ...state.invoiceReducer },
		user: { ...state.userReducer },
	};
}

export default connect(mapStateToProps)(AdminDashboard);