import * as types from './types';

const initialState = {
    message: '',
    data: {
        url: ''
    },
    invoice_pagination_data: {}
};

function invoiceReducer(state = initialState, action) {
    let new_state = state;
    switch (action.type) {
        case types.POST_CHECKOUT_OKAY:
            new_state = Object.assign({}, state, { ...state, data: action.payload.data });
            break;
        case types.POST_CHECKOUT_FAIL:
            new_state = Object.assign({}, state, { ...action.payload });
            break;
        case types.GET_INVOICE_LIST_OKAY:
                new_state = Object.assign({}, state, { ...state, invoice_pagination_data: action.payload });
            break;
        case types.GET_INVOICE_LIST_FAIL:
                new_state = Object.assign({}, state, { ...state, invoice_pagination_data: {} });
            break;
        default:
            break;
    }
    return new_state;
}
export default invoiceReducer;
