import React, { Component } from 'react';
import { Alert, Spinner, Modal, Form, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import axios from 'axios';
import { getAuthorizationToken, userKey  } from '../services/session.service';
import InfoLogo from './assets/info.png';

import amplitude from 'amplitude-js';

import Snackbar from '@material-ui/core/Snackbar';
// import Alert from '@material-ui/lab/Alert';

// <-- FOR STRIPE --> 
import {Elements, CardElement, useStripe, useElements, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
// import './CardSectionStyles.css'
// <-- FOR STRIPE --/> 

const apiBaseUrl = process.env.REACT_APP_API_URL;

// <-- FOR STRIPE --> 
const stripe_pk = process.env.REACT_APP_STRIPE_PK;
const stripePromise = loadStripe(stripe_pk);
const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
    hidePostalCode: true
};

// <-- FOR STRIPE --/> 

class CheckoutModal extends Component {
    state = { 
        isModalLoading: true,
        isPaymentLoading: false,
        isPromoCodeValidating: false,
        paymentMethod: "stripe",
        selectedRadio: 0,
        isStripeError: false,
        stripeErrMsg: "",
        checkoutData: {
            product_id: this.props.data.product_id,
            product_amount: this.props.data.amount, // will change based on plan type
			total_amount: 0,
            promo_code_id: "",
            discount: 0,
            setup_type: this.props.demoCheckout ? this.props.demoCheckoutData.setup_type : this.props.setupTypes[0].type,
            setup_price: this.props.demoCheckout ? this.props.demoCheckoutData.setup_price : this.props.setupTypes[0].price,
            seo_type: this.props.demoCheckout ? this.props.demoCheckoutData.seo_type : this.props.seoTypes[0].type,
            seo_price: this.props.demoCheckout ? this.props.demoCheckoutData.seo_price : this.props.seoTypes[0].price,
            seo_type1: this.props.demoCheckout ? this.props.demoCheckoutData.seo_type : this.props.seoTypes[1].type,
            seo_price1: this.props.demoCheckout ? this.props.demoCheckoutData.seo_price : this.props.seoTypes[1].price,
            plan_type: "Premium",
            plan_id: ""
        },
        plan_types: [],
        str_promo_code: ""
    }

    componentDidMount() {
        // console.log("this.state.checkoutData",this.state.checkoutData)
        this.getProductPlanTypes(this.props.data.product_id);
    }

    getProductPlanTypes = (id) => {
        return axios
		.get(apiBaseUrl+'/api/product/plan-types/get/'+id,
		{
			headers: {'Content-Type':'application/json'}
		})
		.then(response => {
            this.setState({
                ...this.state,
                checkoutData:{
                    ...this.state.checkoutData,
                    plan_id: response.data.plan_types[0].stripe_plan_id,
                    plan_type: response.data.plan_types[0].type,
                },
                plan_types: response.data.plan_types,
                isModalLoading: false,
            }, () => {
                this.computeTotalAmount();
            })
		});
    }

    getUserPromoCodes = () => {
        
    }
    
    validatePromoCode = () => {
        this.setState({
            isPromoCodeValidating: true,
            isStripeError: false,
            stripeErrMsg: ""
        })
        return axios
		.get(apiBaseUrl+'/api/user/promo-codes/get/'+localStorage.getItem(userKey), 
		{
			headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
		})
		.then(response => {
            let validated = false;
            for(let count = 0; count < response.data.promo_codes.length; count++){
                if(response.data.promo_codes[count].promo_code === this.state.str_promo_code && response.data.promo_codes[count].currency == (this.props.currency == "1" ? "USD" : "PHP")){
                    this.setState({
                        ...this.state,
                        checkoutData:{
                            ...this.state.checkoutData,
                            promo_code_id: response.data.promo_codes[count].id,
                            discount: (response.data.promo_codes[count].discount),
                        }
                    }, () => {
                        this.computeTotalAmount();
                    })
                    validated = true;
                    break;
                }
                else if(response.data.promo_codes[count].promo_code === this.state.str_promo_code && (response.data.promo_codes[count].currency == null || response.data.promo_codes[count].currency == "")){
                    this.setState({
                        ...this.state,
                        checkoutData:{
                            ...this.state.checkoutData,
                            promo_code_id: response.data.promo_codes[count].id,
                            discount: (response.data.promo_codes[count].discount),
                        }
                    }, () => {
                        this.computeTotalAmount();
                    })
                    validated = true;
                    break;
                }
            }
            if(!validated){
                this.setState({
                    isPromoCodeValidating: false,
                    isStripeError: true,
                    stripeErrMsg: "Promo code invalid."
                })
            }
            else{
                this.setState({
                    isPromoCodeValidating: false,
                })
            }
		});
    }

    handlePromoCodeChange = (e) => {
        e.preventDefault();
        this.setState({
            ...this.state,
            str_promo_code: e.target.value
        });
    }

    renderRadioSetupTypes = (data) => {
        return(
            data.map((item, index) => {
                return(
                    <React.Fragment>
                        <OverlayTrigger
                            trigger="hover"
                            placement={"top"}
                            overlay={
                                <Popover id="popover-contained">
                                    <div className="additional-service-wrap" dangerouslySetInnerHTML={{__html: item.html_description}}>
                                    </div>
                                </Popover>
                            }
                        >
                            <Form.Check 
                                inline 
                                label={item.string_description} 
                                name={item.type}
                                value={item.price}
                                type="checkbox"
                                onChange={this.setupTypeOnChange.bind(this)}
                                checked={this.state.checkoutData.setup_type == item.type ? true : false}
                            />
                        </OverlayTrigger>
                        <br/>
                    </React.Fragment>
                );
            })   
        );
    }

    renderRadioSeoTypes = (data) => {
        return(
            data.map((item, index) => {
                return(
                    index == 0 ?
                    <React.Fragment>
                        <OverlayTrigger
                            trigger="hover"
                            placement={"top"}
                            overlay={
                                <Popover id="popover-contained">
                                    <div className="additional-service-wrap" dangerouslySetInnerHTML={{__html: item.html_description}}>
                                    </div>
                                </Popover>
                            }
                        >
                            <Form.Check 
                                inline 
                                label={item.string_description} 
                                name={item.type}
                                value={item.price}
                                type="checkbox"
                                onChange={this.seoTypeOnChange.bind(this)}
                                checked={this.state.checkoutData.seo_type == item.type ? true : false}
                            />
                        </OverlayTrigger>
                        <br/>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <OverlayTrigger
                            trigger="hover"
                            placement={"top"}
                            overlay={
                                <Popover id="popover-contained">
                                    <div className="additional-service-wrap" dangerouslySetInnerHTML={{__html: item.html_description}}>
                                    </div>
                                </Popover>
                            }
                        >
                            <Form.Check 
                                inline 
                                label={item.string_description} 
                                name={item.type}
                                value={item.price}
                                type="checkbox"
                                onChange={this.seoTypeOnChange.bind(this)}
                                checked={this.state.checkoutData.seo_type1 == item.type ? true : false}
                            />
                        </OverlayTrigger>
                        <br/>
                    </React.Fragment>
                    
                );
            })   
        );
    }

    renderRadioPlanTypes = (data) => {
        return(
            data.map((item, index) => {
                return(
                    <React.Fragment>
                        {/* <OverlayTrigger
                            trigger="hover"
                            placement={"top"}
                            overlay={
                                <Popover id="popover-contained">
                                    <div className="additional-service-wrap" dangerouslySetInnerHTML={{__html: item.html_description}}>
                                    </div>
                                </Popover>
                            }
                        > */}
                            <Form.Check 
                                inline 
                                id={item.stripe_plan_id}
                                label={item.type + (this.props.currency == "1" ? " $" : " ₱") + this.formatAmount(item.price)+"/month"} 
                                name={item.type}
                                value={item.price}
                                type="checkbox"
                                onChange={this.planTypeOnChange.bind(this)}
                                checked={this.state.checkoutData.plan_type == item.type ? true : false}
                            />
                            <br/>
                        {/* </OverlayTrigger> */}
                    </React.Fragment>
                );
            })   
        );
    }

    formatAmount = (amount) => {
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        return priceSplitter(parseFloat(Math.round(amount * 100) / 100).toFixed(2))
    }

    computeTotalAmount = () => {
        const {product_amount, setup_price, seo_price, discount, seo_price1} = this.state.checkoutData;
        let total_amount = parseFloat(product_amount + setup_price + seo_price + seo_price1);
        total_amount = discount == "100%" ? 0.00 : parseFloat(total_amount - (total_amount * parseFloat("0."+discount)));
        this.setState({
            ...this.state,
            checkoutData: {
                ...this.state.checkoutData,
                total_amount: total_amount
            }
        }, () => {
            console.log("this.state", this.state);
        })
    }
    
    setupTypeOnChange = (e) => {
        this.setState({
            ...this.state,
            checkoutData: {
                ...this.state.checkoutData,
                setup_type: e.target.name,
                setup_price: parseFloat(e.target.value)
            }
        }, () => {
            this.computeTotalAmount();
        })
    }

    seoTypeOnChange = (e) => {
        if(e.target.name == "basic1"){
            this.setState({
                ...this.state,
                checkoutData: {
                    ...this.state.checkoutData,
                    seo_type1: e.target.name,
                    seo_price1: parseFloat(e.target.value)
                }
            }, () => {
                this.computeTotalAmount();
            })
        }else if(e.target.name == "none"){
            this.setState({
                ...this.state,
                checkoutData: {
                    ...this.state.checkoutData,
                    seo_type1: e.target.name,
                    seo_price1: parseFloat(e.target.value),
                    seo_type: e.target.name,
                    seo_price: parseFloat(e.target.value)
                }
            }, () => {
                this.computeTotalAmount();
            })
        }else{
            this.setState({
                ...this.state,
                checkoutData: {
                    ...this.state.checkoutData,
                    seo_type: e.target.name,
                    seo_price: parseFloat(e.target.value)
                }
            }, () => {
                this.computeTotalAmount();
            })
        }
    }

    planTypeOnChange = (e) => {
        console.log('plan_id', e.target.id)
        this.setState({
            ...this.state,
            checkoutData: {
                ...this.state.checkoutData,
                plan_type: e.target.name,
                plan_id: e.target.id,
                product_amount: parseFloat(e.target.value)
            }
        }, () => {
            this.computeTotalAmount();
        })
    }

    renderPlanTypes = () => {
        return(
            <React.Fragment>
                {['checkbox'].map((type, index) => (
                    <div key={`inline-${type}`} className="">
                        {this.renderRadioPlanTypes((this.state.plan_types))} 
                    </div>
                ))}
            </React.Fragment>
        );
    }

    renderAdditionalServices = () => {
        return(
            <React.Fragment>
                {['checkbox'].map((type, index) => (
                    <div key={`inline-${type}`} className="mb-3">
                        {this.renderRadioSetupTypes((this.props.setupTypes))} 
                        <Form.Check 
                            inline 
                            name="none"
                            value="0"
                            label={"None"} 
                            type={type}
                            onChange={this.setupTypeOnChange.bind(this)}
                            checked={
                                this.state.checkoutData.setup_type === 'none' ?
                                    true
                                :
                                    false
                            }
                        />
                    </div>
                ))}
                {['checkbox'].map((type, index) => (
                    <div key={`inline-${type}`}>
                        {this.renderRadioSeoTypes((this.props.seoTypes))} 
                        <Form.Check 
                            inline 
                            name="none"
                            value="0"
                            label={"None"} 
                            type={type}
                            onChange={this.seoTypeOnChange.bind(this)}
                            checked={
                                this.state.checkoutData.seo_type === 'none' && this.state.checkoutData.seo_type1 === 'none' ?
                                    true
                                :
                                    false
                            }
                        />
                    </div>
                ))}
            </React.Fragment>
        );
    }

   

    // <-- FOR STRIPE CHECKOUT -->
    executeStripeCheckout = (stripe, elements) => {
        this.setState({
            isPaymentLoading: true,
            isStripeError: false,
            stripeErrMsg: ""
        })

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        amplitude.getInstance().init(process.env.REACT_APP_APMPLITUDE_KEY);
        amplitude.getInstance().logEvent("USER: WEBSITE CHECKOUT", {
            email: this.props.user.email_address,
            product_id: this.props.data.product_id,
            product_name: this.props.data.product_name,
        }, () => {
            if(this.props.user.stripe_id === null || this.props.user.stripe_id === ""){
                this.createCustomerPaymentMethod(stripe, elements);
            }
            else{
                this.createCustomerSubscription(this.props.user.stripe_id, stripe);
            }
        });
                
        
    };

    createCustomerPaymentMethod = async (stripe, elements) => {
        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                email: this.props.user.email_address,
            },
        });

        if(result.error){
            this.setState({
                isPaymentLoading: false,
                isStripeError: true,
                stripeErrMsg: result.error.message
            })
        }

        else{
            return axios
            .post(apiBaseUrl+'/api/customer/create/'+localStorage.getItem(userKey), 
            {
                email: result.paymentMethod.billing_details.email,
                payment_method: result.paymentMethod.id
            },{
                headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
            })
            .then(response => {
                if(response.data.status){
                    this.createCustomerSubscription(response.data.result.id);
                }
                else{
                    this.setState({
                        isPaymentLoading: false,
                        isStripeError: true,
                        stripeErrMsg: response.data.message
                    })
                }
            })
            .catch(response => {
                this.setState({
                    isPaymentLoading: false,
                    isStripeError: true,
                    stripeErrMsg: response
                })
            });
        }
    }
    
    createCustomerSubscription = (stripe_id, stripe) => {
        const product_data = this.props.data;
        const {promo_code_id, seo_type, setup_type, plan_id, seo_type1} = this.state.checkoutData
        return axios
        .post(apiBaseUrl+'/api/subscription/create/'+localStorage.getItem(userKey), 
        {
            customer_id: stripe_id,
            plan_id: plan_id,
            promo_code_id: promo_code_id,
            seo_type: seo_type,
            seo_type1: seo_type1,
            setup_type: setup_type

        },{
            headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
        })
        .then(response => {
            if(response.data.status){
                this.handleCreateSubscriptionResponse(response.data.result, stripe);
            }
            else{
                this.setState({
                    isPaymentLoading: false,
                    isStripeError: true,
                    stripeErrMsg: response.data.message
                })
            }
        })
        .catch(response => {
            this.setState({
                isPaymentLoading: false,
                isStripeError: true,
                stripeErrMsg: response
            })
        });
        
    }

    handleCreateSubscriptionResponse = (response, stripe) => {
        try
        {
            const { latest_invoice } = response;
            const { payment_intent } = latest_invoice;
            const subscription_id = response.id;
    
            if (payment_intent) {
                const { client_secret, status } = payment_intent;
                if (status === 'requires_action') {
                    stripe.confirmCardPayment(client_secret).then(result => {
                    if (result.error) {
                        this.setState({
                            isPaymentLoading: false,
                            isStripeError: true,
                            stripeErrMsg: result.error
                        })
                        console.log('confirmCardPayment error', result)
                    } else {
                        this.handleSuccessStripePayment(subscription_id);
                    }
                    });
                } else {
                    this.handleSuccessStripePayment(subscription_id);
                }
            }
            else if(payment_intent == null){
                this.handleSuccessStripePayment(subscription_id);
            }
        }
        catch(ex)
        {
            // call delete customer stripe_id
            // call delete subscription where status = null
            this.setState({
                isPaymentLoading: false,
                isStripeError: true,
                stripeErrMsg: "Your payment was declined. Please try again."
            })
        }
        
    }

    handleSuccessStripePayment = (subscription_id) => {
        // call create website endpoint here
        return axios
        .post(apiBaseUrl+'/api/website/create/'+localStorage.getItem(userKey), 
        {
            id: subscription_id
        },{
            headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
        })
        .then(response => {
            // alert(response.data);
            if(response.data.status){
                amplitude.getInstance().init(process.env.REACT_APP_APMPLITUDE_KEY);
                amplitude.getInstance().logEvent("USER: WEBSITE PAYMENT SUCCESS", {
                    email: this.props.user.email_address,
                    product_id: this.props.data.product_id,
                    product_name: this.props.data.product_name,
                }, () => {
                    window.location.replace(response.data.url);
                });
            }
            else{
                this.setState({
                    isPaymentLoading: false,
                    isStripeError: true,
                    stripeErrMsg: response.data.message
                })
            }
        })
        .catch(response => {
            this.setState({
                isPaymentLoading: false,
                isStripeError: true,
                stripeErrMsg: 'Payment success. An error occured while building your website.'
            })
        });
    }
    // <-- FOR STRIPE CHECKOUT --/>

    
    render() { 
        const data = this.props.data;
        const showModal = () => {
            if(this.props.demoCheckout) {
                window.location.reload()
            }
            else{
                this.props.showCheckoutModal()
            }
        };
        return ( 
            <React.Fragment>
                {
                    !this.props.demoCheckout ?
                    <Button
                        className="checkout-btn"
                        onClick={showModal}
                    >
                        Checkout
                    </Button>
                    :
                    null
                }
                <Modal 
                    id="customer-checkout-modal"
                    show={this.props.show} 
                    onHide={showModal} 
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Elements stripe={stripePromise}>
                    <Modal.Header closeButton>
                        <Modal.Title>Checkout</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.isModalLoading ?
                                <center>
                                    <Spinner animation="grow" variant="danger" size="sm" />
                                    <Spinner animation="grow" variant="warning" size="sm" />
                                    <Spinner animation="grow" variant="success" size="sm" />
                                </center>
                            :
                                <React.Fragment>
                                    {
                                        this.state.isStripeError ?
                                            <React.Fragment>
                                                {
                                                    window.screen.width <= 991 ?
                                                        <Snackbar open={true} autoHideDuration={6000} onClose={() => {this.setState({ isStripeError: false })}}>
                                                            <Alert variant="danger">
                                                                {this.state.stripeErrMsg}
                                                            </Alert>
                                                        </Snackbar>
                                                    :
                                                        <Alert variant="danger">
                                                            {this.state.stripeErrMsg}
                                                        </Alert>
                                                }
                                            </React.Fragment>
                                        :
                                            null
                                    }

                                    <Form>
                                        {/* <Form.Group as={Row} controlId="formPlaintextPaymentMethod">
                                            <Form.Label column sm="3">
                                                Payment Method:
                                            </Form.Label>
                                            <Form.Label column sm="9">
                                                { this.state.paymentMethod === "stripe" ? "Stripe" : "Paypal" }
                                            </Form.Label>
                                        </Form.Group> */}
                                        <Form.Group as={Row} controlId="formPlaintextProductName">
                                            <Form.Label column sm="3">
                                                Theme Name:
                                            </Form.Label>
                                            <Form.Label column sm="9">
                                                {data.product_name}
                                            </Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextPlanTypes">
                                            <Form.Label column sm="3">
                                                Plan Type:
                                            </Form.Label>
                                            <Form.Label column sm="9">
                                                {this.renderPlanTypes()}
                                            </Form.Label>
                                        </Form.Group>
                                        {/* <Form.Group as={Row} controlId="formPlaintextProductPrice">
                                            <Form.Label column sm="3">
                                                Product Price:
                                            </Form.Label>
                                            <Form.Label column sm="9">
                                                {this.formatAmount(this.state.checkoutData.product_amount) + " USD / month (Price is based on selected Plan Type)"}
                                            </Form.Label>
                                        </Form.Group> */}
                                        <Form.Group as={Row} controlId="formPlaintextProductDescription">
                                            <Form.Label column sm="3">
                                                Description:
                                            </Form.Label>
                                            <Form.Label column sm="9">
                                                {data.description}
                                            </Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextAdditionalServices">
                                            <Form.Label column sm="3">
                                                Additional Services:
                                            </Form.Label>
                                            <Form.Label column sm="9">
                                                {this.renderAdditionalServices()}
                                            </Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextAmount">
                                            <Form.Label column sm="3">
                                                Total Amount:
                                            </Form.Label>
                                            <Form.Label column sm="9">
                                                {(this.props.currency == 1 ? " $" : " ₱")+(this.state.checkoutData.total_amount === 0 && this.state.checkoutData.discount == "" ? this.formatAmount(data.amount) : this.formatAmount(this.state.checkoutData.total_amount))} {this.state.checkoutData.discount != "" ? " with "+this.state.checkoutData.discount+" discount." : "" }
                                            </Form.Label>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextPromoCode">
                                            <Form.Label column sm="3">
                                                Promo Code:
                                            </Form.Label>
                                            <Col sm="7">
                                                <Form.Control name="promo-code" type="text" placeholder="Enter your Promo Code" onChange={this.handlePromoCodeChange.bind(this)}/>
                                                {/* <Form.Control as="select" onChange={this.handlePromoCodeChange.bind(this)}>
                                                    <option value="">{this.props.promoCodes.length === 0 ? "N/A" : ""}</option>
                                                    {
                                                        this.props.promoCodes.map((item, index) => {
                                                            return(
                                                            <option key={index} value={item.id} discount={item.discount} >{item.promo_code+" - "+item.discount+"%"}</option>
                                                            )
                                                        })
                                                    }
                                                </Form.Control> */}
                                            </Col>
                                            <Col sm="2">
                                                <Button 
                                                    onClick={() => {this.validatePromoCode()}} 
                                                    disabled={this.state.isPromoCodeValidating}
                                                    className="promo-code-btn"
                                                    style={{
                                                        backgroundColor: '#dee2e6',
                                                        width: '100%',
                                                        border: '1px solid #dee2e6'
                                                    }}
                                                >
                                                    {
                                                        this.state.isPromoCodeValidating ?
                                                            <span>
                                                                <Spinner animation="grow" variant="danger" size="sm" />
                                                                <Spinner animation="grow" variant="warning" size="sm" />
                                                                <Spinner animation="grow" variant="success" size="sm" />
                                                            </span>
                                                        :
                                                            'Apply'
                                                    }
                                                </Button>
                                            </Col>
                                        </Form.Group>
                                        {
                                            // IF THE USER HAS NO REGISTERED STRIPE ID SHOW THE CARD ELEMENT FOR CARD REGISTRATION
                                            this.props.user.stripe_id == null || this.props.user.stripe_id == "" ?
                                                <Form.Group as={Row} controlId="formPlaintextStripeInput">
                                                    <Form.Label column sm="3">
                                                        Card Details:
                                                    </Form.Label>
                                                    <Col sm="9">
                                                        <CardElement className options={CARD_ELEMENT_OPTIONS} />
                                                    </Col>
                                                </Form.Group>
                                            :
                                                <Form.Group as={Row} controlId="formPlaintextStripeInput">
                                                    <Form.Label column sm="3">
                                                        Current Card Number:
                                                    </Form.Label>
                                                    <Form.Label column sm="9">
                                                        {"#### #### #### "+this.props.user.card_details}
                                                    </Form.Label>
                                                </Form.Group>
                                        }
                                        
                                    </Form>
                                </React.Fragment>
                        }
                            
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            window.screen.width > 991 ?
                            <Button onClick={showModal} disabled={this.state.isPaymentLoading}>
                                Cancel
                            </Button>
                            :
                            null
                        }
                        {
                            this.state.paymentMethod === "stripe" ?
                            <ElementsConsumer>
                                {({stripe, elements}) => (
                                    <Button type="submit" className="checkout-button" onClick={() => {this.executeStripeCheckout(stripe, elements)}} disabled={this.state.isPaymentLoading}>
                                        {
                                            this.state.isPaymentLoading ?
                                            
                                            <span>
                                                Please wait
                                                <Spinner className="ml-2" animation="border" variant="light" size="sm" />
                                            </span>
                                        :
                                            'Proceed to Checkout'
                                        }
                                    </Button>
                                )}
                            </ElementsConsumer>
                            :
                            null
                        }
                        
                    </Modal.Footer>
                    </Elements>
                </Modal>
            </React.Fragment>
        );
    }
}
 
function mapStateToProps(state) {
	return {
		user: { ...state.userReducer },
	};
}
export default connect(mapStateToProps)(CheckoutModal);