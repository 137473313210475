import React, { Component } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ErrorIcon from '@material-ui/icons/Error';

export class FormUserDetails extends Component {
	next = (e) => {
		e.preventDefault();
		// avoid next step if the required are not fulfilled
		const required = {
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			emailAddress: this.state.emailAddress
		};
		for (const key in required) {
			if (required.hasOwnProperty(key)) {
				const value = required[key];
				if (!value || value === '') {
					return;
				}
			}
		}
		this.props.nextStep();
	};

	constructor() {
		super();
		this.state = {
			firstName: '',
			lastName: '',
			emailAddress: ''
		};
	}

	updatefirstName(event) {
		console.log('First Name:' + event.target.value);
		this.props.handleChange('firstName')(event);
	}
	updatelastName(event) {
		console.log('Last Name:' + event.target.value);
		this.props.handleChange('lastName')(event);
	}
	updateemailAddress(event) {
		console.log('Email Address:' + event.target.value);
	}
	change = (name) => (event) => {
		this.setState({ [name]: event.target.value });
		this.props.handleChange(name)(event);
	};
	render() {
		const { values } = this.props;
		return (
			<div className="form-container">
				<header className="form-header">
					<img className="logo" alt="Prosperna" src={Logo} />
				</header>

				<div className="form-description">
					{
						window.screen.width <= 991 ?
						<React.Fragment>
							<h1>Welcome! Tell us <br/> your name.</h1>
							<p>Success is built on relationships so let's get<br/> to know each other.</p>
						</React.Fragment>
						
						:
						<React.Fragment>
							<h1>Welcome! Tell us your name.</h1>
							<p>Success is built on relationships so let's get to know each other.</p>
						</React.Fragment>
					}
					
				</div>
				<div className="error-container" style={{ visibility: 'hidden' }}>
					<div className="error-icon">
						<ErrorIcon color="error" />
					</div>
					<div className="error-txt">
						<p>Please fill in Username and Password</p>
					</div>
				</div>

				<form className="form-fields">
					<div className="form-row">
						<TextField
							label="First Name"
							variant="outlined"
							className="input-txt"
							onChange={this.change('firstName')}
							value={values.firstName}
						/>
						<TextField
							label="Last Name"
							variant="outlined"
							className="input-txt"
							onChange={this.change('lastName')}
							value={values.lastName}
						/>
					</div>
					<div className="form-row">
						<TextField
							label="Email Address"
							variant="outlined"
							className="input-txt"
							onChange={this.change('emailAddress')}
							value={values.emailAddress}
						/>
					</div>
					<div className="helper-txt">
						<p>*Required</p>
					</div>
				</form>
				<div className="stepper-btn-container">
					<Button className="next-btn" onClick={this.next}>
						Next
					</Button>
					<p>
						Already have an account?{' '}
						<Link className="login-link" href="/login">
							Log In
						</Link>
					</p>
				</div>

				<div className="footer-container">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</div>
			</div>
		);
	}
}

export default FormUserDetails;
