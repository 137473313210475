import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;
export const sessionKey = btoa('sessionKey');
export const userKey = btoa('userKey');
export function verifySesion() {
	console.log('localStorage.getItem(sessionKey)', localStorage.getItem(sessionKey));
	return axios.get(apiBaseUrl + '/api/token', {
		params: {
			token: localStorage.getItem(sessionKey) || '',
			user_id: localStorage.getItem(userKey) || ''
		}
	});
}
export function resetSession() {
	const userDemoCheckout = JSON.parse(localStorage.getItem('userDemoCheckout'));
	const redirect = localStorage.getItem('redirect');
	console.log('session cleared');
	localStorage.clear();
	localStorage.setItem("userDemoCheckout", JSON.stringify(userDemoCheckout));
	localStorage.setItem("redirect", redirect);
}

export function getAuthorizationToken() {
	const authToken = localStorage.getItem(sessionKey);
	const id = localStorage.getItem(userKey);
	return btoa(JSON.stringify({
		session_hash: authToken || '',
		user_id: id || ''
	}));
}
