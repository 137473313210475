import axios from 'axios';
import { getAuthorizationToken, userKey } from './session.service';
const apiBaseUrl = process.env.REACT_APP_API_URL;

export function updateUser(newData = {}) {
    return axios.put(
        apiBaseUrl + '/api/user/' + localStorage.getItem(userKey) || '',
        { ...newData },
        { headers: { 'Content-Type': 'application/json', Authorization: getAuthorizationToken() } }
    );
}

export function forgotPassword(email = '') {
    return axios.post(apiBaseUrl + '/api/user/forgot-password', { email_address: email });
}
