import * as types from './types';
import { userKey } from '../../services/session.service';
import { postCheckout, getSubscriptionListing, getSubscriptionListingPage } from '../../services/invoice.service';
const apiBaseUrl = process.env.REACT_APP_API_URL;

export function postUserCheckoutOkay(payload) {
    return { type: types.POST_CHECKOUT_OKAY, payload };
}
export function postUserCheckoutFail(payload) {
    return { type: types.POST_CHECKOUT_FAIL, payload };
}

export function postUserCheckout(checkout_data = {}) {
    const userId = localStorage.getItem(userKey) || '';
    return (dispatch) => {
        return postCheckout(userId, checkout_data)
            .then((response) => {
                console.log("response.data",response.data.url)
                window.location.replace(apiBaseUrl+response.data.url);
                // console.log('response.data ', response.data);
                // dispatch(postUserCheckoutOkay({ data: response.data }));
                // return Promise.resolve(response.data);
            })
            .catch((error) => {
                dispatch(postUserCheckoutFail({ message: error }));
                return Promise.reject(error);
            });
    };
}


export function getUserInvoiceOkay(payload) {
    return { type: types.GET_INVOICE_LIST_OKAY, payload };
}
export function getUserInvoiceFail() {
    return { type: types.GET_INVOICE_LIST_FAIL };
}

export function getUserSubscription() {
    const userId = localStorage.getItem(userKey) || '';
    return (dispatch) => {
        return getSubscriptionListing(userId)
            .then((response) => {
                console.log('getinvoiceListing response.data ', response.data);
                dispatch(getUserInvoiceOkay( response.data));
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                dispatch(getUserInvoiceFail({ message: error }));
                return Promise.reject(error);
            });
    };
}

export function getUserSubscriptionPage(url='') {
    return (dispatch) => {
        return getSubscriptionListingPage(url)
            .then((response) => {
                console.log('getinvoiceListing response.data ', response.data);
                dispatch(getUserInvoiceOkay(response.data));
                return Promise.resolve(response.data);
            })
            .catch((error) => {
                dispatch(getUserInvoiceFail({ message: error }));
                return Promise.reject(error);
            });
    };
}
