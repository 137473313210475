import React, { Component } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import _ from 'lodash';

export class FormPasswordDetails extends Component {
	constructor() {
		super();
		this.state = {
			password: '',
			confirmPassword: ''
		};
	}
	next = (e) => {
		e.preventDefault();
		this.props.nextStep();
	};

	back = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};

	change = (name) => (event) => {
		this.setState({ [name]: event.target.value });
		this.props.handleChange(name)(event);
	};

	finished = () => {
		const required = {
			password: this.state.password,
			confirmPassword: this.state.confirmPassword
		};
		console.log('next required', required);
		for (const key in required) {
			if (required.hasOwnProperty(key)) {
				const value = required[key];
				if (!value || value === '') {
					return;
				}
			}
		}
		this.props.onFinishClicked();
	};
	render() {
		const { values, handleChange, onFinishClicked } = this.props;
		return (
			<div className="form-container">
				<header className="form-header">
					<img className="logo" alt="Prosperna" src={Logo} />
				</header>

				<div className="form-description">
					<h1>Perfect! Now let's setup your secure access.</h1>
					{
						window.screen.width <= 991 ?
						<p>This way, you can easily and securely <br/>access your account.</p>
						:
						<p>This way, you can easily and securely access your account.</p>
					}
				</div>
				<div className="error-container" style={{ visibility: 'hidden' }}>
					<div className="error-icon">
						<ErrorIcon color="error" />
					</div>
					<div className="error-txt">
						<p>Password doesn't match</p>
					</div>
				</div>

				<form className="form-fields">
					<div className="form-row-pass password">
						<Password handleChange={this.change} />
					</div>

					<div className="policy-txt">
						{
							window.screen.width <= 991 ?
								<p>
									By clicking "COMPLETE ACCOUNT SETUP", you agree <br/>to the{' '}
									<Link
										className="terms-of-service-link"
										href="https://app.prosperna.com/web/terms-and-condition"
										target="_blank"
									>
										Terms of Service
									</Link>,
									<br />
									<Link
										className="terms-of-service-link"
										href="https://app.prosperna.com/web/privacy-policy"
										target="_blank"
									>
										Data Privacy and Spam Policy
									</Link>.
								</p>
							:
								<p>
								By clicking "COMPLETE ACCOUNT SETUP", you agree to the{' '}
									<Link
										className="terms-of-service-link"
										href="https://app.prosperna.com/web/terms-and-condition"
										target="_blank"
									>
										Terms of Service
									</Link>,
									<br />
									<Link
										className="terms-of-service-link"
										href="https://app.prosperna.com/web/privacy-policy"
										target="_blank"
									>
										Data Privacy and Spam Policy
									</Link>.
								</p>
						}
						
					</div>
				</form>
				<div className="stepper-btn-container">
					<Button className="set-up-billing-btn" href="" onClick={this.finished}>
						Complete Acount Setup
					</Button>

					<p style={{ visibility: 'hidden' }}>
						Already have an account?{' '}
						<Link className="login-link" href="/login">
							{' '}
							Log In
						</Link>
					</p>
				</div>

				<div className="footer-container">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</div>
			</div>
		);
	}
}

export default FormPasswordDetails;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	margin: {
		margin: theme.spacing(1)
	},
	withoutLabel: {
		marginTop: theme.spacing(3)
	},
	textField: {
		width: 200
	}
}));

export function Password({ handleChange }) {
	const classes = useStyles();
	const [ values, setValues ] = React.useState({
		amount: '',
		password: '',
		confirmPassword: '',
		weight: '',
		weightRange: '',
		showPassword: false
	});

	const handleChangeInternal = (prop) => (event) => {
		setValues({ ...values, [prop]: event.target.value });
		handleChange(prop)(event);
	};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<div>
			<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={values.showPassword ? 'text' : 'password'}
					value={values.password}
					onChange={handleChangeInternal('password')}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
							>
								{values.showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					}
					labelWidth={70}
				/>
			</FormControl>
			<FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={values.showPassword ? 'text' : 'password'}
					value={values.confirmPassword}
					onChange={handleChangeInternal('confirmPassword')}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
							>
								{values.showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					}
					labelWidth={135}
				/>
			</FormControl>
		</div>
	);
}
