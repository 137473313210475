import React, { Component, Fragment } from 'react';
import Logo from './assets/prospernav2-log.png';
import './Styles.css';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { getAuthorizationToken, userKey  } from '../services/session.service';

import axios from 'axios';
const apiBaseUrl = process.env.REACT_APP_API_URL;

export class ThankYouPage extends Component {
	constructor() {
		super();
		this.state = {
			pdfButtonVisibility: true,
			domainName: [],
			isNoData: false,
			isLoading: true
		};
	}
	componentDidMount(){
		console.log("thank you")
		const arrayURL = window.location.href.split("/");
		this.getDomainName(arrayURL);
	}
	getSubscriptionId = (arrayURL) => {
		const invoiceNumber = arrayURL[arrayURL.length - 2];
		return invoiceNumber;
	}
	getUserId = (arrayURL) => {
		const userId = arrayURL[arrayURL.length - 2];
		return userId;
	}
	getDomainName = (arrayURL) => {
		return axios
		.get(apiBaseUrl+'/api/user/domain/get/'+this.getSubscriptionId(arrayURL)+'/'+localStorage.getItem(userKey), 
		{
			headers: {'Content-Type':'application/json', Authorization: getAuthorizationToken()}
		})
		.then(response => {
			this.setState({
				domainName: "https://"+response.data.domain+".prosperna.ph/admin",
				isLoading: false
			})
		});
	}
	render() {
		return (
			this.state.isLoading ? 
			<p>Loading...</p>
			:
			<div className="form-container">
				<header className="form-header">
					<img className="logo" alt="Prosperna" src={Logo} />
				</header>

				<div className="ty-container">
					<div className="ty-title">
						<h1>Thank you!</h1>
						<p>Your new website is set up and ready to go!</p>
					</div>
					{
						<div className="manage__themes-container">
							<div className="manage__themes-txt">
								<h1>Website Admin Login</h1>
							</div>
							<div className="manage__themes-btn">
								<Button 
									href={this.state.domainName}
									target="_blank"
									variant="contained" 
									color="primary" 
									endIcon={<ChevronRightIcon />}
								>
									GO
								</Button>
							</div>
						</div>
					}
					<div className="manage__prod-container">
						<div className="manage__prod-txt">
							<h1>Manage My Products</h1>
						</div>
						<div className="manage__prod-btn">
							<Button
								href="/my-products"
								variant="contained"
								color="primary"
								endIcon={<ChevronRightIcon />}
							>
								GO
							</Button>
						</div>
					</div>
				</div>

				<div className="footer-container">
					<p>
						An Xtendly Company <br /> © 2020 Prosperna
					</p>
				</div>
			</div>
		);
	}
}

export default ThankYouPage;
